import { DialogType, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useBoolean } from '@fluentui/react-hooks';
import { SavedChat } from "../../api";
import { ReactSVG } from "react-svg";

import close from "../../assets/close.svg"
import styles from "./SideBarContents.module.scss";
import ReactMarkdown from "react-markdown";
import infoTheme, {darkInfoTheme} from "../../fluidThemes/infoTheme"


interface Props {
    historyState: SavedChat;
    isRetrieving: boolean;
    onChatHistorySessionClickHandler: (session: string) => () => Promise<void>
    onShowDialog: (session: string) => () => void
}

interface MonthlyActivity {
    [month: string]: string[]
}

interface MonthComparator {
    month: string;
    comparator: undefined | Date;
}

export const SideBarContents = ({
    historyState,
    isRetrieving,
    onChatHistorySessionClickHandler,
    onShowDialog
}: Props) => {
    const [monthlyActivity, setMonthlyActivity] = useState<MonthlyActivity>({})
    const monthComparator = useRef<{ [month: string]: undefined | Date }>({})
    

    useEffect(() => {
        console.log("History State", historyState)
        console.log("Monthly Activity", monthlyActivity.current)
    }, [])
    useEffect(() => {
        const setOfSessions: { [month: string]: Set<string> } = {};
        const tempMonthlyActivity: MonthlyActivity = { ...monthlyActivity }
        Object.keys(historyState).forEach(k => {
            const session = historyState[k];
            const modified = session?.modified_timestamp;
            const { month, comparator } = toRecentMonth(modified);
            monthComparator.current[month] = comparator;
            setOfSessions[month] = setOfSessions[month] || new Set();
            setOfSessions[month].add(k);
        });
        Object.keys(setOfSessions).forEach(k => {
            tempMonthlyActivity[k] = [...setOfSessions[k]].sort(sortDateStrings)
        })
        setMonthlyActivity(tempMonthlyActivity)
    }, [historyState]);

    const sortedMoths = () => {
        const months = [...Object.keys(monthlyActivity)];
        months.sort((a, b) => (monthComparator.current[b]?.getTime() ?? NaN) - (monthComparator.current[a]?.getTime() ?? NaN));
        return months;
    }

    const sortDateStrings = (session_a: string, session_b: string) => {
        const datestring_a = historyState[session_a]?.modified_timestamp,
            datestring_b = historyState[session_b]?.modified_timestamp;
        if (!datestring_a || !datestring_b) return NaN;
        const dt_a = new Date(datestring_a);
        const dt_b = new Date(datestring_b);
        return dt_b.getTime() - dt_a.getTime(); // sort descending
    }

    const toRecentMonth = (datetime?: string): MonthComparator => {
        if (!datetime) return { month: "Unknown Date", comparator: undefined };
        const dt_string = new Date(datetime);
        const dtst_mo_yr = dt_string.toLocaleDateString(undefined, { month: "long", year: "numeric" });
        const dt_mo_yr = new Date(dtst_mo_yr);
        const now = new Date();
        if (dt_string.getMonth() === now.getMonth() && dt_string.getFullYear() === now.getFullYear()) return { month: "This Month", comparator: dt_mo_yr };
        if (dt_string.getFullYear() === now.getFullYear()) return { month: dt_string.toLocaleDateString(undefined, { month: "long" }), comparator: dt_mo_yr };
        return { month: dtst_mo_yr, comparator: dt_mo_yr }
    }

    const toRecentTime = (datetime?: string) => {
        if (!datetime) return "";
        const dt_string = new Date(datetime);
        const now = new Date();
        const diff = now.getTime() - dt_string.getTime();
        if (diff < 60000) return "Now";
        if (diff < 300000) return "a few minutes ago"
        if (dt_string.toLocaleDateString() === now.toLocaleDateString()) return dt_string.toLocaleTimeString();
        return dt_string.toLocaleDateString(undefined, { day: "numeric", month: "short", year: "numeric" })
    }

    return (
        <>
            <Stack className={styles.chatSessionsPanel} tabIndex={0} role="tabpanel" aria-label="Recent Activity Panel">
                <h4 className={styles.header}>Recent Conversations</h4>
                {isRetrieving ? (
                    <div className={styles.loading}>
                        <Spinner label="Loading previous chat, please wait...." ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} theme={infoTheme} />
                    </div>
                ) :
                    sortedMoths().map(month => (
                        <Stack.Item
                            key={month}
                            className={styles.monthblock}
                        >
                            <div className={styles.month}>{month}</div>
                            {[...monthlyActivity[month]].filter(session => historyState[session]).map(session => (
                                <Stack.Item
                                    key={session}
                                    className={styles.outerSession}
                                >
                                    <div
                                        onClick={onChatHistorySessionClickHandler(session)}
                                        className={styles.session}>
                                        <ReactMarkdown className={styles.sessionTitle} components={{ p: 'span' }}>{historyState[session].title}</ReactMarkdown>
                                        <div className={styles.sessionDateTime}>{toRecentTime(historyState[session]?.modified_timestamp)}</div>
                                    </div>
                                    <div className={styles.deleteButton} onClick={onShowDialog(session)}>
                                        <ReactSVG src={close} />
                                    </div>
                                </Stack.Item>
                            ))}
                        </Stack.Item>
                    ))
                }
            </Stack>
        </>
    )
}
import { useEffect, useState } from "react";
import { UserClaims } from "@okta/okta-auth-js";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
 
const Role_Test = "GPT_TESTING";
const Role_Admin = "GPT_ADMIN";
const Role_User = "GPT_USER";
const Role_Media = "GPT_MEDIA";

export const authUserRole = () => {
    const { instance } = useMsal();
        const [isAdminUser,setIsAdminUser] = useState<boolean>(false);
        const [isNormalUser,setIsNormalUser] = useState<boolean>(false);
        const [isMediaUser,setIsMediaUser] = useState<boolean>(false);
        const [isTestingUser,setIsTestingUser] = useState<boolean>(false);
        useEffect(() => {
            const getUser = async () => {
                try {
                    
                    const userInfo: UserClaims|undefined = (await instance.acquireTokenSilent(loginRequest)).idTokenClaims as UserClaims|undefined;

                    if (userInfo?.roles?.toString()?.toUpperCase()?.includes(Role_User)) {
                        setIsNormalUser(true);
                        }
                    if (userInfo?.roles?.toString().toUpperCase()?.includes(Role_Media)) {
                        setIsMediaUser(true);
                        }
                    if (userInfo?.roles?.toString().toUpperCase()?.includes(Role_Admin)) {
                        setIsAdminUser(true);
                        }
                    if (userInfo?.roles?.toString().toUpperCase()?.includes(Role_Test)) {
                        setIsTestingUser(true);
                        }
                } catch (error) {
                        console.log(error);
                }
            };
            getUser();
        }, []);

        return {isAdminUser,isMediaUser,isNormalUser, isTestingUser};
};
export default authUserRole;
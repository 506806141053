import { AppEntity, AppEntityKeySet } from "../../api";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Typography, Popover } from "@mui/material";
import { useState } from "react";
import styles from "./AppSelector.module.scss";
import { compare } from "../../utils/comparisonUtils";

interface Props {
    entities: AppEntity[];
    canUseDraftMode: boolean;
    isInDraftMode: boolean;
    setActiveApp: (activeApp: AppEntity | undefined) => void;
    activeApp: AppEntity | undefined;
}

const AppSelector = ({ entities, canUseDraftMode, isInDraftMode, setActiveApp, activeApp }: Props) => {
    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'App',
            flex: 1,
            headerClassName: styles.header
        },
        {
            field: canUseDraftMode && isInDraftMode ? 'categories' : 'published_categories',
            headerName: 'Categories',
            renderCell: (params) => params.value?.length ?? 'none',
            getApplyQuickFilterFn: undefined,
            width: 75,
            headerClassName: styles.header
        }
    ]

    if (canUseDraftMode) {
        columns.push(
            {
                field: 'hasDraft',
                headerName: 'Version',
                type: 'string',
                getApplyQuickFilterFn: undefined,
                valueGetter: (_, row) => AppEntityKeySet.some(k => row[k[1]] === undefined) ? 'U' : AppEntityKeySet.some(k => !compare(row[k[0]], row[k[1]])) ?  'D': 'P',
                width: 70,
                headerClassName: styles.header
            }
        );
    }
    const QuickSearchToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 0.5, pb: 0, }}>
                <GridToolbarQuickFilter sx={{ flex: 1, paddingRight: '5px', color: "var(--text-white)" }} />
            </GridToolbarContainer>
        );
    }
    const [popoverValue, setPopoverValue] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        const field = event.currentTarget.dataset.field;
        const id = event.currentTarget.parentElement!.dataset.id;
        const row = entities.find(e => e.RowKey === id);
        let val: string[] = []
        switch (field) {
            case 'categories': val = row?.categories ?? []; break;
            case 'published_categories': val = row?.published_categories ?? []; break;
            case 'title': val = [canUseDraftMode && isInDraftMode ? row?.title : row?.published_title].filter(t => t !== undefined); break;
            case 'hasDraft': val = [AppEntityKeySet.some(k => row![k[1]] === undefined) ? 'Unpublished' : AppEntityKeySet.some(k => !compare(row![k[0]], row![k[1]])) ?  'Draft available' : 'Published']
            default: break;
        }
        if (val && val.length > 0) {
            setPopoverValue(val);
            setAnchorEl(event.currentTarget)
        }
    }
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <DataGrid
                columns={columns}
                rows={entities}
                getRowId={(e: AppEntity) => e.RowKey}
                sx={{
                    height: '100%',
                    fontSize: '.8rem'
                }}
                disableColumnMenu
                disableColumnResize={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }]
                    },
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    }
                }}
                density="compact"
                onRowClick={({ id }) => setActiveApp(entities.find(e => e.RowKey === id.toString()))}
                hideFooterSelectedRowCount={true}
                pageSizeOptions={[10]}
                slots={{ toolbar: QuickSearchToolbar }}
                slotProps={{
                    cell: {
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    },
                }}
                getRowClassName={(params) => params.id === activeApp?.RowKey ? styles.selectedRow : styles.row}
            />
            <Popover
                sx={{
                    pointerEvents: 'none'
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ p: 1 }}>
                    {popoverValue.map(p =>
                        <Typography key={p}>{p}</Typography>
                    )}
                </Box>
            </Popover>
        </>
    )
}

export default AppSelector;

import { Panel, PanelType, Stack } from "@fluentui/react";
import styles from "./RecentActivityPanel.module.scss";
import { SavedChat } from "../../api";
import { SideBarContents } from "../SideBarContents/SideBarContents";
import UserPresence from "../UserPresence";

interface Props {
    isPanelOpen: boolean;
    historyState: SavedChat;
    isRetrieving: boolean;
    setPanelClose: () => void;
    onChatHistorySessionClickHandler: (session: string) => () => Promise<void>
    onShowDialog: (session: string) => () => void
}

export const RecentActivityPanel = ({
    isPanelOpen,
    historyState,
    isRetrieving,
    setPanelClose,
    onChatHistorySessionClickHandler,
    onShowDialog
}: Props) => {
    return (
        <Panel
            headerClassName={styles.panelHeader}
            isOpen={isPanelOpen}
            isBlocking={false}
            type={Number(PanelType.customNear)}
            customWidth="340px"
            onDismiss={setPanelClose}
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            isHiddenOnDismiss={true}
            className={styles.recentActivityPanel}
            layerProps={{
                style: {
                    zIndex: 10
                },
                styles: {
                    root: {
                        zIndex: 11
                    }
                }
            }}
        >
            <Stack className={styles.SideBar}>
                <SideBarContents
                    historyState={historyState}
                    isRetrieving={isRetrieving}
                    onChatHistorySessionClickHandler={onChatHistorySessionClickHandler}
                    onShowDialog={onShowDialog}
                />
                <UserPresence className={styles.userPresence} />
            </Stack>
        </Panel>
    );
};

import { Panel, PanelType, Stack } from "@fluentui/react";
import styles from "./SideBar.module.scss";
import { getIsAppActive, SavedChat } from "../../api";
import { SideBarContents } from "../SideBarContents/SideBarContents";
import { SideBarItem } from "../SideBarItem/SideBarItem";
import { ReactSVG } from "react-svg";
import { PropsWithChildren, useEffect, useId, useState } from "react";
import { event, callNewChat } from "../../interfaces/newchat"

import authUserRole from "../../utils/AuthUserRole";
import UserPresence from "../UserPresence";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Route, Routes, useLocation } from "react-router-dom";
interface Props {
    className?: string;
}

export const SideBar = ({
    className,
    children
}: PropsWithChildren<Props>) => {
    const [groups, setInitials] = useState<string[] | undefined>(undefined)
    const { instance } = useMsal();
    const [selectedId, setSelectedId] = useState<string>();
    const { isAdminUser, isNormalUser, isMediaUser, isTestingUser } = authUserRole();
    const [activeApps, setActiveApps] = useState<string[]>([]);

    useEffect(() => {
        Promise.all([getIsAppActive('admin_documents'), getIsAppActive('admin_createanapp'), getIsAppActive('admin_structured_data')])
            .then(([admin_documents, admin_createanapp, admin_structureddata]) => {
                const apps = []
                if (admin_documents) { apps.push('admin_documents') }
                if (admin_createanapp) { apps.push('admin_createanapp') }
                if (admin_structureddata) { apps.push('admin_structured_data') }
                setActiveApps(apps);
            })
    }, [])

    useEffect(() => {
        instance.acquireTokenSilent(loginRequest)
            .then(auth => {
                const groups = (auth.idTokenClaims as any)?.groups as string[] | undefined;
                if (groups) {
                    setInitials(groups)
                }
            });
    }, [])
    const newchatId = useId();
    const [newChatDisabled, setNewChatDisabled] = useState<boolean>(true);
    useEffect(() => {
        event.add(setNewChatDisabled);

        return () => {
            event.remove(setNewChatDisabled);
        }
    }, []);
    useEffect(() => {
        const url = window.location.href;
        let itemRout = url.substring(url.lastIndexOf("/"), url.length);
        itemRout = itemRout == "/" ? "/Chat" : itemRout;
        setSelectedId(itemRout)
    }, []);
    return (
        <Stack className={[styles.SideBar, className].filter(x => x).join(' ')}>
            {(isAdminUser && activeApps.find(v => v === 'admin_createanapp')) &&
                <SideBarItem name="Create an App" route="/App-Creator"></SideBarItem>
            }
            {(isMediaUser && !isAdminUser && activeApps.find(v => v === 'admin_documents')) &&
                <SideBarItem name="Media" route="/Documents"></SideBarItem>
            }
            {(isAdminUser && activeApps.find(v => v === 'admin_documents')) &&
                <SideBarItem name="Documents" route="/Documents"></SideBarItem>
            }
            {(isAdminUser && activeApps.find(v => v === 'admin_structured_data')) &&
                <div style={{ display: 'none' }}>
                    <SideBarItem name="Structured Data Training" route="/Structured-Training"></SideBarItem>
                </div>
            }
            {((isMediaUser || isAdminUser || isTestingUser) && activeApps.length > 0) &&
                <SideBarItem name="Chat Interface" route="/Chat" match_at_root={true}></SideBarItem>
            }
            {children}
        </Stack>
    );
};

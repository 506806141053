import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Stack, Text } from "@fluentui/react";

import styles from "./Answer.module.scss";

import { AskResponse, ChatMessage, Citation } from "../../api";
import { getAndReplaceCitationFromResponse, parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import copy from "../../assets/copy2.svg";
import regen from "../../assets/regenerate.svg";
import { ReactSVG } from "react-svg";

interface Props {
    answer: AskResponse;
    isCurrent: boolean;
    onCitationClicked: (citedDocument: Citation) => void;
    onRegenerateClicked: () => Promise<void>;
}


export const Answer = ({
    answer,
    isCurrent,
    onCitationClicked,
    onRegenerateClicked
}: Props) => {
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;

    const parsedAnswer = useMemo(() => getAndReplaceCitationFromResponse(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [metadataExpanded, { toggle: toggleMedataExpanded }] = useBoolean(false)

    const [isCopied, setIsopied] = useState<boolean>(false);

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    const createCitationTitle = (citation: Citation, index: number, truncate: boolean = false) => {
        if (citation.title) {
            if (truncate && citation.title.length > filePathTruncationLimit) {
                const citationLength = citation.title.length;
                return `${citation.title.substring(0, 20)}...${citation.title.substring(citationLength - 20)}`
            }
            else {
                return citation.title
            }
        }
        else {
            return `Citation ${index}`;
        }
    }
    const handleCopyClick = () => {
        setIsopied(false);
        navigator.clipboard.writeText(parsedAnswer.markdownFormatText);
        copyDelay(1000);
    };

    const copyDelay = (timeout: number) => {
        setIsopied(true);
        window.setTimeout(() => {
            setIsopied(false);
        }, timeout);
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>

                <Stack.Item grow>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className={styles.answerText}
                    />
                </Stack.Item>
                {!!parsedAnswer.citations.length && (
                    <Stack.Item>
                        <Stack style={{ width: "100%" }} >
                            <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                <Text
                                    className={styles.accordionTitle}
                                    onClick={toggleIsRefAccordionOpen}
                                    aria-label="Open references"
                                    tabIndex={0}
                                    role="button"
                                >
                                    <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " references" : "1 reference"}</span>
                                </Text>
                                <FontIcon className={styles.accordionIcon}
                                    onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                />
                            </Stack>
                        </Stack>
                    </Stack.Item>
                )}
                {chevronIsExpanded &&
                    <div style={{ marginTop: 8, display: "flex", flexFlow: "wrap column", maxHeight: "150px", gap: "4px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationTitle(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    className={styles.citationContainer}
                                    aria-label={createCitationTitle(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationTitle(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
                <Stack horizontal className={styles.answerFooter}>
                    <Stack.Item className={styles.answerDisclaimerContainer} onClick={toggleMedataExpanded}>
                        {metadataExpanded ? (
                            <Stack>
                                <span className={styles.answerDisclaimer}>AI-generated content may be incorrect</span>
                                {answer.answer_metadata?.bounded && <span className={styles.answerDisclaimer}>Answer may not be exhaustive as the question was identified as unbound</span>}
                                {answer.answer_metadata?.docs_filter && <span className={styles.answerDisclaimer}>Document filter: {answer.answer_metadata?.docs_filter}</span>}
                                {answer.answer_metadata?.stats_filter && <span className={styles.answerDisclaimer}>Stats filter: {answer.answer_metadata?.stats_filter}</span>}
                                {answer.answer_metadata?.news_filter && <span className={styles.answerDisclaimer}>News filter: {answer.answer_metadata?.news_filter}</span>}

                            </Stack>
                        ) : (
                            <span className={styles.answerDisclaimer}>AI-generated content may be incorrect</span>
                        )}
                    </Stack.Item>
                    <Stack horizontal className={styles.answerHeader}>
                        <div
                            className={`${styles.buttonContainer} ${isCopied && 'copied'}`}
                            role="button"
                            tabIndex={1}
                            aria-label="Copy"
                            title="Copy response"
                            onClick={handleCopyClick}
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                        >
                            <ReactSVG className={styles.copyButton} src={copy} />
                        </div>
                        {isCurrent && (<div
                            className={styles.buttonContainer}
                            role="button"
                            tabIndex={0}
                            aria-label="Regenerate"
                            title="Regenerate response"
                            onClick={onRegenerateClicked}
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? onRegenerateClicked() : null}
                        >
                            <ReactSVG className={styles.regenButton} src={regen} />
                        </div>)}
                    </Stack>
                </Stack>

            </Stack>
        </>
    );
};

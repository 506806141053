export const getType = (any: any) => Object.prototype.toString.call(any).slice(8, -1).toLowerCase();
export const isArray = <T,>(any: T|T[]): any is T[] => getType(any) === 'array'

export const compare = <T = string|string[]|Date|undefined>(a: T, b: T) => {
    if(a === undefined || b === undefined) return false;
    const a_type = getType(a);
    if(a_type !== getType(b)) return false;
    if(isArray(a) && isArray(b)){
        return a.length === b.length && a.every(_a => b.includes(_a))
    }
    else {
        return a === b;
    }
}
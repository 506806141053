import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Stack, IStackTokens, IComboBoxStyles, ICheckboxProps, Checkbox, ProgressIndicator, Callout, DirectionalHint, Text, Spinner, SpinnerSize, PrimaryButton } from "@fluentui/react";
import { FilePond, registerPlugin } from "react-filepond";
import { toast } from "react-toastify"
import "filepond/dist/filepond.min.css";
import styles from "./uploader.module.scss";
import infoTheme from "../../fluidThemes/infoTheme";
import accentTheme from "../../fluidThemes/accentTheme";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePondFile, FilePondInitialFile } from "filepond";
import { cleanupFiles, getConfig, getSessionFiles, processFiles, UploadedFile } from "../../api";
import { InfoFilled, InfoRegular } from "@fluentui/react-icons";

import pdf_icon from "../../assets/file_type_pdf_icon.svg";
import pptx_icon from "../../assets/file_type_powerpoint_icon.svg";
import docx_icon from "../../assets/file_type_word_icon.svg";
import txt_icon from "../../assets/file_type_text_icon.svg";
import xslx_icon from "../../assets/file_type_excel_icon.svg";
import csv_icon from "../../assets/csv_icon 1.svg";
import infofilled_icon from "../../assets/info-filled.svg";
import infooutline_icon from "../../assets/la_info.svg";
import { useBoolean } from "@fluentui/react-hooks";
import { ReactSVG } from "react-svg";
import { OverridesContext } from "../../interfaces/overridesContextManagement";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const LOW = 0.5
const MEDIUM = 0.75
const OVERRUN = 1.0

interface UploaderProps {
    onSelectedFilesChanged: React.Dispatch<React.SetStateAction<string[]>>;
    onRemainingTokensChanged: React.Dispatch<React.SetStateAction<number>>;
    sessionId: string | undefined;
    currentText: string | undefined;
    setSessionId: (sessionId: string) => void;
}

interface ICheckBoxOptions { props: ICheckboxProps, extension: string | undefined }

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Uploader = ({ onSelectedFilesChanged, onRemainingTokensChanged, sessionId, setSessionId, currentText }: UploaderProps) => {

    const [files, setFiles] = useState<FilePondFile[]>([]);
    const [uploaded, setUploadedFiles] = useState<UploadedFile[]>([]);
    const [promptTokens, setPromptTokens] = useState<number | undefined>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
    const [checkboxlist, setCheckBoxList] = useState<ICheckBoxOptions[]>([]);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isProcessing, { toggle: toggleIsProcessing }] = useBoolean(false);
    const [isFilePondProcessing, { setTrue: setProcessing, setFalse: setDone }] = useBoolean(false);
    const overridesState = useContext(OverridesContext);
    const { instance } = useMsal();

    useEffect(() => {
        window.onbeforeunload = () => {
            instance.acquireTokenSilent(loginRequest)
                .then(auth => cleanupFiles([... new Set(files.map(f => f.serverId).concat(uploaded.map(u => u.id)))], auth.accessToken));
            setFiles([]);
            setUploadedFiles([]);
            setSelectedFiles([]);
        }
    }, [files, uploaded])

    useEffect(() => {
        if (sessionId) {
            instance.acquireTokenSilent(loginRequest)
                .then(token => getSessionFiles(sessionId, token.accessToken))
                .then(files => {
                    setFiles([]);
                    setUploadedFiles(files);
                    setSelectedFiles([]);
                });
        }
        else {
            instance.acquireTokenSilent(loginRequest)
                .then(auth => cleanupFiles([... new Set(files.map(f => f.serverId).concat(uploaded.map(u => u.id)))], auth.accessToken));
            setFiles([]);
            setUploadedFiles([]);
            setSelectedFiles([]);
        }
    }, [sessionId])

    useEffect(() => {
        getConfig<number>("prompttokensconsumed").then(tokens => setPromptTokens(tokens));
    }, []);

    useEffect(() => {
        const tokensRemaining = (overridesState.selectedDeployment?.token_limit ?? 0) - getSelectedTokens();
        onRemainingTokensChanged(tokensRemaining);
    }, [overridesState, promptTokens, selectedFiles])

    useEffect(() => {
        const checkBoxes = uploaded.map<ICheckBoxOptions>(file => {
            return {
                props: {
                    id: file.id,
                    label: `${file.filename} - ${file.tokens} tokens`,
                    onRenderLabel: renderLabel(file),
                    onChange: (_ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked: boolean | undefined) => onCheckBoxChange(file.id, checked)
                },
                extension: file.filename.split('.').at(-1)
            }
        })
        setCheckBoxList(checkBoxes)
    }, [uploaded, selectedFiles])

    useEffect(() => onSelectedFilesChanged(selectedFiles.map(sf => sf.id)), [selectedFiles])

    const onCheckBoxChange = (id: string, checked: boolean | undefined) => {
        const selectedFile = uploaded.find(u => u.id === id)
        if (checked === undefined || selectedFile === undefined) return;
        const newArr = selectedFiles.slice()
        let deletesuccess: boolean | undefined = undefined;
        if (checked) {
            newArr.push(selectedFile);
        }
        else {
            const ix = newArr.indexOf(selectedFile)
            !!~ix && newArr.splice(ix, 1)
        }
        setSelectedFiles(newArr);
    }

    const getTextTokens = () => Math.ceil((currentText?.length ?? 0) / 4);
    const getSelectedTokens = () => selectedFiles.reduce((acc, cur) => acc += +cur.tokens, promptTokens ?? 0) + getTextTokens();
    const getPercentage = () => {
        if (overridesState.selectedDeployment?.token_limit === undefined || getSelectedTokens() === undefined) return undefined;
        return Math.min(getSelectedTokens() / overridesState.selectedDeployment.token_limit, 1)
    }
    const getProgressClass = () => {
        const percentComplete = getPercentage();
        if (percentComplete === undefined) return styles.progressBarIndeterminate;
        if (percentComplete < LOW) return styles.progressBarLow;
        if (percentComplete < MEDIUM) return styles.progressBarMedium;
        if (percentComplete < OVERRUN) return styles.progressBarHigh;
        return styles.progressBarOverrun;
    }

    const getImageForExtension = (extension: string) => {
        switch (extension) {
            case 'pdf': return pdf_icon;
            case 'docx': return docx_icon;
            case 'pptx': return pptx_icon;
            case 'txt': return txt_icon;
            case 'xlsx': return xslx_icon;
            case 'csv': return csv_icon
            default: return txt_icon;
        }
    }

    const renderLabel = (file: UploadedFile) => () => {
        return (
            <div className={styles.checkboxLabel}>
                <img src={getImageForExtension(file.filename.split('.').at(-1) ?? '')} className={styles.checkboxImg} />
                <div className={styles.checkboxText}>
                    <p className={styles.filenameText}>{file.filename}</p>
                    <p className={styles.tokenText}>{file.tokens} tokens</p>
                </div>
            </div>
        )
    }

    const isAggregateError = (error: Error | AggregateError): error is AggregateError => (error as AggregateError).errors !== undefined;

    const handleSubmit = async () => {
        toggleIsProcessing();
        setSelectedFiles([]);
        try {
            const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
            const uploadedfiles = await processFiles(files.map(f => f.serverId), sessionId, token);
            if (uploadedfiles.sessionId !== sessionId) {
                setSessionId(uploadedfiles.sessionId);
            }
            const uploadedArr = uploaded.slice();
            uploadedArr.push(...uploadedfiles.files)
            setUploadedFiles(uploadedArr);
            setFiles([]);

        } catch (error) {
            const err: Error | AggregateError = error as any;
            if (isAggregateError(err)) {
                err.errors.forEach(e => {
                    toast.error(e.message, { theme: "colored", hideProgressBar: true, autoClose: false })
                });
            }
            else {
                toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false })
            }
        }
        finally {
            toggleIsProcessing();
        }
    };

    const ALLOWEDEXTENSIONS = ["pdf", "docx", "pptx", "txt", "xlsx", "csv"]
    const onBeforeAddFile = async (file: FilePondFile) => {
        const found = !!~ALLOWEDEXTENSIONS.indexOf(file.fileExtension)
        return found;
    }

    return (
        <article className={styles.uploaderArticle}>
            <header className={styles.Banner}>
                <p>Upload your files and click "Add to List" to make them available to ask questions about them.</p>
                <p>The files you upload are private and accessible only to you and only for the current session.
                    You will need to upload the files again if you wish to use them in a different session; however these
                    files will remain if you return to the session at a later date.
                </p>
            </header>
            <section className={styles.Uploader}>
                <Stack className={styles.uploaderStack}>
                    <Stack.Item>
                        <form onSubmit={handleSubmit}>
                            <FilePond
                                className={styles.FilePond}
                                files={files as unknown as FilePondInitialFile[]}
                                onupdatefiles={setFiles}
                                allowMultiple={true}
                                credits={false}
                                server="/upload"
                                beforeAddFile={onBeforeAddFile}
                                name="files"
                                labelIdle='Drag & drop your files or <span class="filepond--label-action">Browse</span>'
                                onprocessfile={(error, file) => {
                                    if (error) {
                                        console.log('Oh no', error);
                                    } else {
                                        console.log('File processed', file);
                                    }
                                }}
                                onaddfilestart={(file) => {
                                    console.log('Start adding', file);
                                    setProcessing();
                                }}
                                onprocessfiles={setDone}
                            />
                            <div className={styles.iconText}>
                                <ReactSVG src={infooutline_icon} className={styles.iconRegularInfo} />Supported Files: {ALLOWEDEXTENSIONS.join(", ").toUpperCase()}
                            </div>
                            <PrimaryButton onClick={handleSubmit} disabled={isProcessing || isFilePondProcessing} className={styles.infoButton} theme={infoTheme}>
                                Add to List
                            </PrimaryButton>
                        </form>
                    </Stack.Item>
                    {isProcessing ? (
                        <div className={styles.loading}>
                            <Spinner label="Processing files and calculating tokens, please wait...." ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} theme={infoTheme} />
                        </div>
                    ) : (
                        <>
                            <Stack.Item className={styles.filesdisplay}>
                                {checkboxlist.length ? (
                                    <>
                                        <h4 className={styles.fileList}>Files List</h4>
                                        <p>
                                            Choose files for questions, making sure the total tokens stay within the budget.
                                        </p>
                                        {checkboxlist.map(cb => {
                                            return (
                                                <Checkbox {...cb.props} data-extension={cb.extension} key={cb.props.id} theme={infoTheme} />
                                            )
                                        })}
                                    </>
                                ) : null}
                            </Stack.Item>
                            <Stack.Item className={styles.tokenbarcontainer} style={{ position: "relative" }}>
                                <ProgressIndicator
                                    theme={accentTheme}
                                    description={`Total tokens available: ${(overridesState.selectedDeployment?.token_limit ?? 'unknown')}`}
                                    label={(
                                        <>
                                            <span>Consuming {getSelectedTokens()} tokens</span>
                                            <button id="help-text" style={{ border: "0", backgroundColor: "transparent", cursor: "pointer" }} onClick={toggleIsCalloutVisible}>
                                                <ReactSVG src={infofilled_icon} className={styles.iconInfo} beforeInjection={(svg) => {
                                                    svg.setAttribute('width', '15px');
                                                    svg.setAttribute('height', '15px');
                                                }} />
                                            </button>
                                        </>)}
                                    percentComplete={getPercentage()}
                                    className={`${styles.progressBar} ${getProgressClass()}`}
                                />

                                {isCalloutVisible ? (
                                    <Callout
                                        theme={infoTheme}
                                        role="dialog"
                                        gapSpace={0}
                                        target="#help-text"
                                        onDismiss={toggleIsCalloutVisible}
                                        style={{ width: 320, maxWidth: "90%", padding: "20px 24px" }}
                                        setInitialFocus
                                    >
                                        <Text block variant="small" theme={infoTheme}>
                                            Please note, token usage is approximate and may not take into consideration the entire chat
                                            history of this session. The chat history may be truncated (keeping only the latest messages) depending on the number of tokens available.
                                            Token usage also covers the chat answer.
                                        </Text>
                                    </Callout>
                                ) : null}
                            </Stack.Item>
                        </>)}
                </Stack>
            </section>
        </article>
    );
};

export default Uploader;

import * as React from "react";
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
} from "@fluentui/react-tag-picker-preview";
import { Tag, useComboboxFilter, Button } from "@fluentui/react-components";
import { getALLTags } from "../../api";
import { toast } from "react-toastify";
import styles from "./TagpickerComponent.module.scss";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

interface TagpickerComponentProps {
  setSelectedTags: (SelectedTags: string[]) => void;
  selectedTags: string[]
}
export const TagpickerComponent = ({ setSelectedTags, selectedTags }: TagpickerComponentProps) => {
  const onOptionSelect: TagPickerProps["onOptionSelect"] = (_e: any, data: any) => {
    if (data.optionText != '') {
      setSelectedTags(data.selectedOptions);
    }
    setQuery("");
  };
  const { instance } = useMsal();
  const [options, setTagOptions] = React.useState<string[]>([]);
  const isAggregateError = (error: Error | AggregateError): error is AggregateError => (error as AggregateError).errors !== undefined;
  const [query, setQuery] = React.useState<string>("");
  const [textError, settextError] = React.useState<string>("");
  const getAllTagsFromApi = async () => {
    try {
      const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
      const ct = await getALLTags(token);
      const options: string[] = [];
      if (ct) {
        if (Array.isArray(ct)) {
          ct.forEach(function (value: string) {
            options.push(value);
          });
          options.sort((a, b) => a.localeCompare(b))
        }
        setTagOptions(options);
      }
    }
    catch (error) {
      const err: Error | AggregateError = error as any;
      if (isAggregateError(err)) {
        err.errors.forEach(e => {
          toast.error(e.message, { theme: "colored", hideProgressBar: true, autoClose: false })
        });
      }
      else {
        toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false })
      }
    }
  }

  React.useEffect(() => {
    getAllTagsFromApi();
  }, [])

  const children = useComboboxFilter(
    query,
    options.map((option, i) => ({ children: option, value: option, i })),
    {
      noOptionsMessage: "Click Add button to Add new Tag",
      renderOption: (option) => (
        <>
          <TagPickerOption
            key={option.value}
            value={option.value}
            className={styles.tagPickerOption}
            id={"TagPickerGroup_tag_" + option.i}
          >
            {option.children}
          </TagPickerOption>
        </>
      ),

      filter(optionText, queryText) {
        return (
          !selectedTags.includes(optionText) &&
          optionText.toLowerCase().includes(queryText.toLowerCase())
        );
      },
    }
  );
  const addNewTag = () => {
    if (query != '') {
      let regex = new RegExp("[^a-zA-Z]+");
      let match = regex.test(query);
      if (match) {
        settextError('Please enter Alphabets only');
      }
      else {
        settextError('');
        if (!selectedTags.includes(query)) {
          setSelectedTags([...selectedTags, query]);
        }
      }
    }
    setQuery("");
  }
  return (
    <TagPicker
      size={"large"}
      onOptionSelect={onOptionSelect}
      selectedOptions={selectedTags}
    >
      <TagPickerControl
        className={styles.tagPickerControl}
        secondaryAction={
          <Button
            className={styles.secondaryAction}
            appearance="primary"
            size="small"
            shape="rounded"
            onClick={addNewTag}
            id="tagpickerbutton"
          >
            Add
          </Button>
        }>
        <TagPickerGroup className={styles.tagPickerGroup}>
          {selectedTags.map((option, i) => (
            <Tag className={styles.tagPickerOption}
              key={option}
              shape="rounded"
              value={option}
              id={"TagPickerGroup_tag_" + i}
            >
              {option}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput
          className={styles.tagPickerInput}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          pattern="A-Za-z"
        />
        <p id="tag_error" className="ms-TextField-errorMessage errorMessage-167">
          <span>{textError}</span></p>
      </TagPickerControl>
      <TagPickerList className={styles.tagPickerList} multiselect={true}>
        {children}
      </TagPickerList>
    </TagPicker>
  );
};
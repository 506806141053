
import { AppEntity, ConversationRequest, SavedChatResponse, TrainingEntity } from "./models";


export async function conversationApi(options: ConversationRequest, access_token: string, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
            messages: options.messages,
            ids: options.ids,
            chat_id: options.chat_id,
            overrides: options.overrides
        }),
        signal: abortSignal
    });

    return response;
}

export async function getChatSessions(access_token: string): Promise<SavedChatResponse[]> {
    const response = await fetch('/getChats', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
        }
    });

    if (!response.ok) {
        throw new Error('Error retrieving chat sessions');
    }

    return response.json();
};

export async function getChatSessionsStream(access_token: string, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch('/getChats', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
        },
        signal: abortSignal
    });

    return response
};

export const getChatSession = async (sessionName: string, access_token: string): Promise<SavedChatResponse> => {
    console.log("Fetching chat session for: ", sessionName);

    const response = await fetch(`/getChat/${sessionName}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
        }
    });

    if (!response.ok) {
        throw new Error(`Error loading chat session: ${response.status} ${response.statusText}`);
    }

    const sessionData = await response.json();
    console.log("Received chat session data: ", sessionData);

    return sessionData;
}

export const deleteChatSession = async (sessionName: string, access_token: string): Promise<void> => {
    const response = await fetch(`/deleteChat/${sessionName}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
        }
    });
    if (!response.ok) {
        throw new Error(`Error deleting chat session: ${response.status} ${response.statusText}`);
    }
}

export interface Photo {
    type: string | undefined;
    value: Blob | undefined
}

let photo: Photo | undefined = undefined
export async function getUserPhoto(access_token: string): Promise<Photo | undefined> {
    if (!photo) {
        const photorequest = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
                Authorization: `bearer ${access_token}`
            }
        });
        if (!photorequest.ok) { return undefined; }
        photo = {
            type: photorequest.headers.get('Content-type') ?? undefined,
            value: await photorequest.blob()
        };
    }
    return photo;
}

export async function getMeTest(access_token: string): Promise<string | undefined> {
    const response = await fetchParsed<string>("/users/me", { method: "GET", headers: { Authorization: `Bearer ${access_token}` } });
    return response;
}

export interface WelcomeText {
    title: string;
    subtitle?: string
}

export async function getWelcomeText(): Promise<WelcomeText | undefined> {
    const response = await fetch('/config/welcome');
    const payload = await response.json() as WelcomeText;
    return payload;
}
export async function getMediaTypesFromAPI(): Promise<string | undefined> {
    const response = await fetch('/config/mediatype');
    const payload = await response.json() as string;
    return payload;
}
export async function getDocumentTypesFromAPI(): Promise<string | undefined> {
    const response = await fetch('/config/documenttype');
    const payload = await response.json() as string;
    return payload;
}
export async function getCategoriesFromAPI(): Promise<string | undefined> {
    const response = await fetch('/config/categories');
    const payload = await response.json() as string;
    return payload;
}
export async function getFileStatusFromAPI(): Promise<string | undefined> {
    const response = await fetch('/config/filestatus');
    const payload = await response.json() as string;
    return payload;
}
export async function getDocumentsData(qString: string, access_token: string): Promise<[IMetaData[] | undefined, IPageInfoBlock | undefined]> {
    const response = await fetch(`/getquery${qString}`,
        {
            method: "GET",
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
    const parsedResponse: IDocumentAPIPayload & { error?: string, errors?: string[] } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map(e => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }
    return [parsedResponse.results, parsedResponse.pageInfo]
}
export async function cleanupFiles(ids: string[], access_token: string): Promise<void> {
    await fetch("/cleanup", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
            ids: ids
        })
    })
}

export interface UploadedFile {
    id: string;
    filename: string;
    tokens: number | string;
}

export interface UploadDocument {
    id: string;
    filename: string;
    type: string;
    category: string;
    description: string;
    uploadedby: string;
    uploadedon: string;
    Tags: string;
}

export interface IDocumentAPIPayload {
    pageInfo: IPageInfoBlock
    results: IMetaData[];
}
export interface IPageInfoBlock {
    numEntries: number;
    numEntriesonCurrentPage: number;
    numPages: number;
    page: number;
    pageSize: number;
}
export interface IRecord {
    container: string;
    creation_time: string;
    last_modified: string;
    name: string;
    metadata: IMetaData;
}
export interface IMetaData {
    id: string,
    category: string,
    date_uploaded?: string;
    description: string;
    metadata_storage_path?: string;
    metadata_storage_name?: string;
    status: string;
    tagarray: string[];
    type: string;
    title: string;

    uploaded_by: string;
}
export interface Updates {
    updates: Update[]
}

export interface Update {
    title: string
    description?: string
    category?: string;
    type?: string
    uploaded_by: string
    tags?: string[];
    mode: string
}

export interface BulkUpdates {
    updates: BulkUpdate[]
}

export interface BulkUpdate {
    title: string
    category?: string
    tags?: string[]
    mode?: string
}
export interface ProcessResponse {
    sessionId: string,
    files: UploadedFile[]
}

export interface DResponse {
    sessionId: string,
    files: UploadedFile[]
}

export interface OpenAIDeployment {
    name: string;
    deployment: string;
    token_limit: number;
    default: boolean;
    query: boolean;
    chat: boolean;
    generate_name: boolean;
    order: number;
}

export async function processDocuments(document_metadata: IMetaData, access_token: string): Promise<boolean> {
    const response = await fetch("/adminuploadfiletoblob", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(document_metadata)
    });
    const parsedResponse: ProcessResponse & { error?: string, errors?: string[] } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map(e => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }
    return true;
}
export async function editDocument(data: Updates, access_token: string): Promise<string> {

    const response = await fetch("/admineditblobs", {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(data)
    });
    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map(e => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export const editDocumentAPI = async (data: BulkUpdates, access_token: string): Promise<string> => {
    const response = await fetch("/admineditblobs", {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(data)
    });

    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? new AggregateError(parsedResponse.errors.map(e => new Error(e))) : new Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
};

export async function deleteFiles(title: string, access_token: string | undefined): Promise<boolean> {
    const data = new FormData();
    data.append("titles", title);
    const response = await fetch(`/adminbulkdeleteblob`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${access_token}`
        },
        body: data
    });
    const parsedResponse: ProcessResponse & { error?: string, errors?: string[] } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map(e => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return true;
}



export async function checkNameExists(name: string | undefined, access_token: string | undefined): Promise<{ exists: boolean, status: string }> {
    if (!name) { return { exists: false, status: '' }; }
    const response = await fetch(`/filenamecheck`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
            "title": name
        })
    });
    const parsedResponse = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}


export async function getALLTags(access_token: string | undefined): Promise<string[]> {
    const response = await fetch("/getalltags", {
        method: "GET",
        headers: {
            Accept: "application/json",
            ContentType: "application/json",
            Authorization: `Bearer ${access_token}`
        }
    });
    const parsedResponse: { distinct_tags: string[] } & { error?: string, errors?: string[] } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map(e => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse.distinct_tags;
}

export async function processFiles(ids: string[], sessionId: string | undefined, access_token: string): Promise<ProcessResponse> {
    const response = await fetch("/processfiles", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({ "ids": ids, "chat_id": sessionId })
    });
    const parsedResponse: ProcessResponse & { error?: string, errors?: string[] } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map(e => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getSessionFiles(sessionId: string, access_token: string): Promise<UploadedFile[]> {
    const response = await fetchParsed<UploadedFile[]>("/sessionfiles", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({ "chat_id": sessionId })
    });
    return response;
}

const fetchParsed = async <T>(uri: string, init: RequestInit = { method: "GET" }): Promise<T> => {
    const response = await fetch(uri, init);
    const parsedResponse: T & { error?: string } = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    const result = parsedResponse;
    return result;
};

const fetchParsedSecure = async <T>(uri: string, access_token: string | undefined, init: RequestInit = {}): Promise<T> => {
    const reqInit: RequestInit = {
        method: "GET", 
        ...init, 
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`
        }
    }
    return fetchParsed(uri, reqInit)
}


export const getConfig = async <T extends string | number | boolean>(config: string) => {
    const fetched = await fetchParsed<{ [k: string]: T }>(`/config/${config}`);
    const fetchedconfig = fetched?.[config];
    return fetchedconfig;
};

export const getIsAppActive = async (app: string) => {
    const fetched = await fetchParsed<{[k:string]: boolean}>(`/config/getactiveapp/${app}`);
    const fetchedconfig = fetched?.[app];
    return fetchedconfig;
}
export type DocumentFilter = { [k: string]: string[] }
export const getDocumentFilters = async (facets: string[] | undefined = undefined) => await fetchParsed<DocumentFilter>(`/docs/getfacets${!!facets ? "?facets=" + facets.join(",") : ""}`)
export const getOpenAIDeployments = async () => await fetchParsed<OpenAIDeployment[]>("/config/openaideployments")

export const getAppsStream = async (access_token: string | undefined, abortSignal: AbortSignal) => {
    const response = await fetch('/app', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
        },
        signal: abortSignal
    });

    return response
}

export const startIndexingDocuments = async (access_token: string | undefined) => {
    const response = await fetch("/docs/indexrun", {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({})
    });
    const parsedResponse: AppEntity & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export const startTranscribingMedia = async (access_token: string | undefined) => {
    const response = await fetch("/docs/transcriber", {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({})
    });
    const parsedResponse: AppEntity & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function cleanupDocs(access_token: string | undefined): Promise<boolean> {
    const response = await fetch(`/docs/cleanup`, {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({})
    });
    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return true;
}

export const getApp = async (rowkey: string, access_token: string | undefined) => fetchParsedSecure<AppEntity>(`/app/${rowkey}`, access_token)

export const createApp = async (appEntity: AppEntity, access_token: string | undefined) => {
    const response = await fetch("/app", {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(appEntity)
    });
    const parsedResponse: AppEntity & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export const updateApp = async (appEntity: AppEntity, access_token: string | undefined) => {
    const rowkey = appEntity.RowKey
    const response = await fetch(`/app/${rowkey}`, {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(appEntity)
    });
    if (response.status === 204){
        return
    }
    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }
}

export async function deleteApp(appEntity: AppEntity, access_token: string | undefined): Promise<boolean> {
    const rowkey = appEntity.RowKey
    const response = await fetch(`/app/${rowkey}`, {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(appEntity)
    });
    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return true;
}

interface TrainingDs {
    id?: string;
    collection: 'documentation' | 'ddl';
    content: string
}

interface TrainingSQL {
    id?: string;
    collection: 'sql';
    content: string;
    question: string;
}

export type TrainingPayload = TrainingDs | TrainingSQL;

export const getTestConnection = async (access_token: string | undefined) => fetchParsedSecure<{connection_test: boolean}>(`/structure/testconnection`, access_token);
export const getTraining = async (access_token: string | undefined) => fetchParsedSecure<TrainingEntity[]>(`/structure/training`, access_token);
export const postTraining = async (access_token: string | undefined, payload: TrainingPayload) => {
    const response = await fetch('/structure/training', {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(payload)
    });
    const parsedResponse: {id: string} & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export const deleteTraining = async (access_token: string | undefined, payload: {id: string}) => {
    if(!payload.id){
        throw Error('ID is required')
    }
    const response = await fetch('/structure/training', {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify(payload)
    });
    const parsedResponse: {id: string} & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export const resetTraining = async (access_token: string | undefined, confirm: boolean = false) => {
    const response = await fetch(`/structure/training/reset`, {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({'confirm': confirm})
    });
    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return true;
}

export const trainTraining = async (access_token: string | undefined, reset: boolean = false) => {
    const response = await fetch(`/structure/train`, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({'reset': confirm})
    });
    const parsedResponse: string & { error?: string, errors?: string[] } = await response.json();

    if (response.status > 299 || !response.ok) {
        throw parsedResponse.errors ? AggregateError(parsedResponse.errors.map((e: string) => Error(e))) : Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
import { createTheme } from "@fluentui/react";

  export const darkTheme = {
    palette: {
      themePrimary: '#0dbd0d',
      themeLighterAlt: '#010801'
    }
  };

  export const defaultTheme = createTheme({
    defaultFontStyle: { fontFamily: "Montserrat, sans-serif", fontWeight: 300, fontSize: "16px" }
});
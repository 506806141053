import { useRef, useState, useEffect } from "react";
import { Stack, IIconProps, IDropdownOption } from "@fluentui/react";
import { SearchBox } from '@fluentui/react/lib/SearchBox';

import { SideBar } from "../../components/SideBar/SideBar";
import { DataTable } from "../../components/DataTable/DataTable";
import styles from "./Documents.module.scss";
import { ThemeProvider } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { DocumentUploadPanel } from "../../components/DocumentUploadPanel";
import { BulkEditDocument } from "../../components/BulkEditDocument/BulkEditDocument";
import { DeleteDocument } from "../../components/DeleteDocument/DeleteDocument";
import { IMetaData, getDocumentsData, getALLTags, getDocumentTypesFromAPI, getMediaTypesFromAPI, getCategoriesFromAPI, getFileStatusFromAPI, IPageInfoBlock, startIndexingDocuments, startTranscribingMedia, getIsAppActive, cleanupDocs } from "../../api";
import darkTheme from "../../fluidThemes/darkTheme";
import { authUserRole } from "../../utils/AuthUserRole"
import { SpinnerUI } from "../../components/Spinner/Spinner";
import { ValueOptions } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridCallbackDetails, GridFilterModel } from "@mui/x-data-grid";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { toast } from "react-toastify";
const Documents = () => {

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [configPivot, setConfigPivot] = useState("uploader");

    const [searchString, setSearchString] = useState<string>();
    const [selectedRowId, setSelectedRowId] = useState<string>();
    const [isSelected, setIsSelected] = useState<boolean>();
    const [documentData, setDocumentData] = useState<IMetaData[]>();
    const [allTags, setAllTags] = useState<ValueOptions[]>()
    const [rowData, setRowData] = useState<IMetaData>();
    const { instance } = useMsal();
    const { isAdminUser, isNormalUser, isMediaUser } = authUserRole();
    const [spinnerMessage, setSpinnerMessage] = useState<string>();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [docTypeName, setDocTypeName] = useState<string>();
    const [pageInfoBlock, setPageInfoBlock] = useState<IPageInfoBlock>({ page: 0, pageSize: 10, numEntries: 0, numPages: 0, numEntriesonCurrentPage: 0 });
    const [ctOptions, setCategoryOptions] = useState<string[]>([]);
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [allowedExtensions, setAllowedExtensions] = useState<string[]>([]);
    const [activeApps, setActiveApps] = useState<string[]>([]);
    const [resetSearch, setResetSearch] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([getIsAppActive('adhoc_indexer'), getIsAppActive('adhoc_transcriber')])
            .then(([adhoc_indexer, adhoc_transcriber]) => {
                const apps = []
                if (adhoc_indexer) { apps.push('adhoc_indexer') }
                if (adhoc_transcriber) { apps.push('adhoc_transcriber') }
                setActiveApps(apps);
            })
    }, [])

    const toggleConfigPannel = () => {
        setConfigPivot("uploader");
        setIsConfigPanelOpen(!isConfigPanelOpen);
    };
    const reloadPage = async () => {
        await getDocumentsFromApi();
    }
    const editRowData = (rowData: IMetaData | undefined) => {
        setRowData(rowData);
        reloadPage();
        toggleConfigPannel();
    }
    const getDocumentsFromApi = async (model: GridFilterModel | undefined = undefined, details: GridCallbackDetails<"filter"> | undefined = undefined) => {
        const qString = await createAPIQuery(model);

        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
        const [dt, pageInfo] = await getDocumentsData(qString, token);

        try {
            if (dt) {
                setDocumentData(dt);
            }
            if (pageInfo) {
                setPageInfoBlock(pageInfo);
            }
        }
        catch (e) {
            const err = e;
        }
    }
    const getDocTypeFromApi = async () => {
        if (isAdminUser || true) {
            let strDocLowerArray: string[] = [];
            let strMediaLowerArray: string[] = [];
            const dt = await getDocumentTypesFromAPI();
            const mt = await getMediaTypesFromAPI();
            if (dt) {
                strDocLowerArray = dt.toLowerCase().split(",");
            }
            if (mt) {
                strMediaLowerArray = mt.toLowerCase().split(",");
            }
            setAllowedExtensions([...strDocLowerArray, ...strMediaLowerArray]);
            return ([...strDocLowerArray, ...strMediaLowerArray]);
        }
    }
    const getCategoryFromApi = async () => {
        const ct = await getCategoriesFromAPI();
        if (ct) {
            let strArray: string[] = ct.split(",");
            const items: string[] | undefined = strArray.map(x => x);
            setCategoryOptions(items);
        }
    }
    const getStatusFromApi = async () => {
        const st = await getFileStatusFromAPI();
        if (st) {
            let strArray: string[] = st.split(",");
            const items: string[] | undefined = strArray.map(x => x);
            setStatusOptions(items);
        }
    }
    const getAllTagsFromApi = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
        const tags = (await getALLTags(token)).map(t => {
            return {
                value: t,
                label: t
            } as ValueOptions
        });
        setAllTags(tags)
    }

    const createAPIQuery = async (model: GridFilterModel | undefined = undefined) => {
        const searchStr = (searchString?.length ?? 0 > 0) ? `search='/.*${searchString}.*/'` : '';
        var filters = model?.items?.map(i => {
            if (i.value != undefined && i.value != '') {
                switch (i.operator) {
                    case "contains":
                        return `search.ismatch('/.*${i.value}.*/','${i.field}','full','any')`;
                        break;
                    case "in":
                        return `search.in(${i.field}, '${i.value}')`
                        break;
                    case "eq":
                        if (i.field == "date_uploaded") {
                            const currentDate = new Date(i.value);
                            const currentDateString = currentDate.toISOString(); //current day for utc day begining
                            currentDate.setDate(currentDate.getDate() + 1)
                            const nextDate = new Date(currentDate);
                            const nextDateString = nextDate.toISOString(); //previous day for utc day ending
                            //to match the date part , filter the dates within the utc day range
                            return `${i.field} ge ${currentDateString.toString()} and ${i.field} le ${nextDateString.toString()}`
                        }
                        else {
                            return `${i.field} eq '${i.value}'`
                        }
                        break;
                    default:
                        return "";
                        break;
                }
            }
        })

        filters = filters?.filter(function (element) {
            return element !== undefined;
        });
        const defaultFilterStr = `search.in(type, '${allowedExtensions}')`;
        filters = filters != undefined ? filters.concat(defaultFilterStr) : [defaultFilterStr];
        const filterstring = (filters?.length ?? 0 > 0) ? 'filter=' + filters?.filter((field) => field != '').join(' and ') : '';
        const pageString = (pageInfoBlock?.page ?? 0) >= 0 ? 'page=' + (pageInfoBlock?.page) : ''
        const pageSizeString = (pageInfoBlock?.pageSize ?? 0) > 0 ? 'pageSize=' + pageInfoBlock?.pageSize : ''
        const queries = [searchStr, filterstring, pageString, pageSizeString].filter(x => x);
        return queries.length > 0 ? '?' + queries.join('&') : "";
    }

    const addIcon: IIconProps = { iconName: 'Add' };
    useEffect(() => {
        if (selectedRowId) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    }, [selectedRowId])
    useEffect(() => {
        setShowSpinner(true)
        var promGetDocs = (allowedExtensions != null && allowedExtensions.length > 0) ? getDocumentsFromApi() : Promise.resolve();
        Promise.all([
            getDocTypeFromApi(),
            getAllTagsFromApi(),
            getStatusFromApi(),
            getCategoryFromApi(),
            promGetDocs,
        ]
        )
            .finally(() => {
                setShowSpinner(false);
            }
            );
    }, [])

    useEffect(() => {
        if (allowedExtensions != null && allowedExtensions.length > 0) {
            setShowSpinner(true);
            getDocumentsFromApi().finally(() => setShowSpinner(false));
        }
    }, [allowedExtensions, pageInfoBlock.page, pageInfoBlock.pageSize])

    useEffect(() => {
        setDocTypeName(`${isAdminUser ? "Document" : isMediaUser ? "Media" : ""}`);
        if (allowedExtensions == null || allowedExtensions.length == 0) {
            getDocTypeFromApi();
        }
    }, [isAdminUser, isMediaUser])

    useEffect(() => {
        if (!isConfigPanelOpen)
            setRowData(undefined);
    }, [isConfigPanelOpen])

    const RunIndexer = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;

        try {
            toast.info("Indexation started");
            await startIndexingDocuments(token);
            toast.success("Indexation finished.");
            reloadPage();
        }
        catch (ex) {
            toast.error("Error triggering indexation", { autoClose: false, theme: 'colored' })
        }
    }

    const RunTranscriber = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;

        try {
            toast.info("Transcription and indexation started");
            await startTranscribingMedia(token);
            toast.success("Transcription and indexation finished.");
            reloadPage();
        }
        catch (ex) {
            toast.error("Error triggering transcription", { autoClose: false, theme: 'colored' })
        }
    }

    const RunCleanupDocuments = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;

        try {
            toast.info("Cleanup started");
            await cleanupDocs(token);
            toast.success("Cleanup finished.");
            reloadPage();
        }
        catch (ex) {
            toast.error("Error triggering cleanup", { autoClose: false, theme: 'colored' })
        }
    }

    useEffect(() => {
        if(!searchString && resetSearch){
            setResetSearch(false);
            getDocumentsFromApi();
        }
    }, [searchString, resetSearch]);

    return (
        <>
            <div className={styles.container} role="main">
                <SideBar>
                    <div className={styles.SideBar}>
                        <div className={styles.header}>Document Indexer</div>
                        <div className={styles.buttons}>
                            {activeApps.find(v => v === 'adhoc_indexer') &&
                                <PrimaryButton
                                    onClick={RunIndexer}
                                    theme={darkTheme}
                                    text="Start Indexing" />
                            }
                            {activeApps.find(v => v === 'adhoc_transcriber') &&
                                <PrimaryButton
                                    onClick={RunTranscriber}
                                    theme={darkTheme}
                                    className={styles.transcriberButton}
                                    text="Start Transcribing" />
                            }
                            <PrimaryButton
                                    onClick={RunCleanupDocuments}
                                    theme={darkTheme}
                                    text="Cleanup Documents" />
                        </div>
                    </div>
                </SideBar>
                <Stack>
                    <div className={styles.title}>{`${docTypeName}`}</div>
                    <div className={styles.control}>
                        <div className={styles.searchbox}>
                            <SearchBox className={styles.searchtext}
                                placeholder={`Search ${docTypeName}`}
                                onChange={(_, newValue) => setSearchString(newValue)}
                                onSearch={async (newValue) => await getDocumentsFromApi()}
                                onClear={() => setResetSearch(true)}
                                disableAnimation
                            />
                            <DefaultButton
                                theme={darkTheme}
                                className={styles.searchboxbutton}
                                onClick={(async () => await getDocumentsFromApi())}
                            >
                                Search
                            </DefaultButton>
                        </div>
                        <DefaultButton className={styles.iconbutton} iconProps={addIcon} title="Emoji" text={`Upload ${docTypeName}`} onClick={toggleConfigPannel} />
                    </div>
                    <div>
                        <DataTable
                            tableData={documentData}
                            editRowData={rd => editRowData(rd)}
                            selectRowId={rd => setSelectedRowId(rd)}
                            allTags={allTags}
                            onFilterModelChange={getDocumentsFromApi}
                            ctOptions={ctOptions}
                            allowedExtensions={allowedExtensions}
                            filestatusOptions={statusOptions}
                            pageInfoBlock={pageInfoBlock}
                            setPageInfoBlock={setPageInfoBlock}
                        />
                    </div>
                    <Stack horizontal className={styles.bottomcontrol} tokens={{childrenGap: 5}}>
                        <BulkEditDocument
                            rowIds={selectedRowId}
                            reloadPage={reloadPage}
                            disabled={!isSelected}
                            setShowSpinner={setShowSpinner} />
                        <DeleteDocument
                            deleteRowId={selectedRowId}
                            reloadPage={reloadPage}
                            disabled={!isSelected}                
                            tableData={documentData}
                            setDocumentData={setDocumentData}
                            setShowSpinner={setShowSpinner} />
                    </Stack>
                </Stack>
            </div>
            <DocumentUploadPanel
                isConfigPanelOpen={isConfigPanelOpen}
                selectedPivot={configPivot}
                setSelectedPivot={setConfigPivot}
                setIsConfigPanelOpen={setIsConfigPanelOpen}                
                tableData={documentData}
                setDocumentData={setDocumentData}
                Metadata={rowData}
                editRowData={rd => editRowData(rd)}
                setShowSpinner={setShowSpinner}
                docTypeName={docTypeName ?? ""}
                ctOptions={ctOptions}
                allowedExtensions={allowedExtensions}
            />
            <SpinnerUI message={spinnerMessage} showSpinner={showSpinner} />
        </>
    )
};

export default Documents;

import { useMsal } from "@azure/msal-react";
import { PropsWithChildren, ReactFragment, useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import Unauthorised from "./Unauthorised";
import Loading from "../components/Loading";
import NoPage from "./NoPage";
import { getIsAppActive } from "../api";

type Role = 'GPT_Admin' | 'GPT_User' | 'GPT_Media' | 'GPT_Testing';
type Ternary = true | false | 'unknown';
interface roleClaim {
    roles?: Role[]
}
interface Props {
    roles: Role[]
    requiredApps?: string[]
}
const Protected = ({ roles, children, requiredApps = [] }: PropsWithChildren<Props>) => {
    const { instance } = useMsal();
    const [Authorised, setAuthorised] = useState<Ternary>("unknown");
    const [HasApps, setHasApps] = useState<Ternary>('unknown');

    useEffect(() => {
        instance.acquireTokenSilent(loginRequest).then(t => {
            const roleClaims = (t.idTokenClaims as roleClaim | undefined)?.roles;
            if (roleClaims === undefined) {
                setAuthorised(false);
            }
            else if (roles.some(r => roleClaims.includes(r))) {
                setAuthorised(true);
            }
            else {
                setAuthorised(false);
            }
        });
        if (requiredApps && requiredApps.length > 0) {
            Promise.all(requiredApps.map(app => getIsAppActive(app)))
                .then(apparray => setHasApps(apparray.every(v => v)));
        }
        else {
            setHasApps(true);
        }
    }, []);

    if (!children || HasApps === false) {
        return <NoPage />
    }
    else if (Authorised === true && HasApps === true) {
        return (<>
            {children}
        </>)
    }
    else if (Authorised === false) {
        return (<Unauthorised />)
    }
    else {
        return (<Loading />)
    }
}

export default Protected;
import { IDropdownOption, Panel, PivotItem } from "@fluentui/react";
import styles from "./DocumentUploadPanel.module.scss";
import DocumentUploader from "../DocumentUploader/DocumentUploader";
import { IMetaData } from "../../api";
interface Props {
    isConfigPanelOpen: boolean;
    selectedPivot: string;
    setSelectedPivot: React.Dispatch<React.SetStateAction<string>>;
    setIsConfigPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setDocumentData: React.Dispatch<React.SetStateAction<IMetaData[]|undefined>>;
    Metadata: IMetaData|undefined; 
    editRowData: (rowData: IMetaData|undefined) => void;
    setShowSpinner:(show: boolean) => void;
    docTypeName:string;
    ctOptions:string[];
    allowedExtensions:string[];
    tableData: IMetaData[]|undefined;
}

export const DocumentUploadPanel = ({
    isConfigPanelOpen,
    selectedPivot,
    setSelectedPivot,
    setIsConfigPanelOpen,
    setDocumentData,
    Metadata,
    editRowData,
    setShowSpinner,
    docTypeName, ctOptions, allowedExtensions,tableData
}: Props) => {

const closePanel=()=>{

}

    const forcePivot = (item: PivotItem | undefined) => {item?.props.itemKey && setSelectedPivot(item.props.itemKey); }

    return (
        <>
            <Panel
                headerClassName={styles.panelHeader}                
                isOpen={isConfigPanelOpen}
                isBlocking={false}
                onDismiss={() => setIsConfigPanelOpen(false)}
                isHiddenOnDismiss={true}
                closeButtonAriaLabel="Close"                
                isFooterAtBottom={true}
                className={styles.configuration}
                layerProps={{
                    style: {
                        zIndex: 10
                    },
                    styles: {
                        root: {
                            zIndex: 11
                        }
                    }
                }}
            >                
                <div  className={styles.panelBody}>
                    <DocumentUploader
                        Metadata={Metadata}
                        editRowData = {rd=>editRowData(rd)}                        
                        setShowSpinner={setShowSpinner}
                        docTypeName={docTypeName}
                        ctOptions={ctOptions}
                        allowedExtensions={allowedExtensions}
                        setDocumentData={setDocumentData}
                        tableData={tableData}/>
                </div>
            </Panel>
        </>
    );
};

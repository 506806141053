import { Checkbox, Stack } from "@fluentui/react";
import { DocumentFilter } from "../../api/api";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "./DocumentFilters.module.scss";
import DownCaret from "../../assets/Down.svg";
import { ReactSVG } from 'react-svg';
import { useContext, useMemo, useState } from "react";
import { OverridesContext } from "../../interfaces/overridesContextManagement";

interface DocFilterProps {
    filter: DocumentFilter | string;
    parent?: string;
    checkall?: boolean;
}

export const DocFilter = ({ filter, parent, checkall = false }: DocFilterProps) => {
    const overridesState = useContext(OverridesContext);
    const selectedFilters = overridesState.selectedDocumentFilters
    const depth = parent?.split(/:+./)?.length ?? 0;

    const [checked, setChecked] = useState(!!(parent && selectedFilters?.[parent]?.find(f => f === filter)));
    
    const onToggleChange = (value: string, state: boolean | undefined) => {
        const selectedFilter = overridesState.selectedDocumentFilters || {};
        const [category, val] = value.split(':', 2);
        if (state) {
            selectedFilter[category] = selectedFilter[category] ? [...new Set(selectedFilter[category].concat(val))]: [val]
        }
        else {
            selectedFilter[category].splice(selectedFilter[category].indexOf(val), 1);
            if (selectedFilter[category].length === 0) {
                delete selectedFilter[category];
            }
        }
        overridesState.setSelectedDocumentFilters(selectedFilter);
        setChecked(state ?? false);
        console.log(value, state);
    }
    if (typeof filter === 'string') {
        const value = parent ? parent + ':' + filter : filter;
        return (
            <Checkbox
                className={toClassArray(styles.filter, `depth-${depth}`)}
                key={value}
                id={value}
                label={filter}
                checked={checked}
                onChange={(_ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked: boolean | undefined) => onToggleChange(value, checked)}
            />
        )
    }
    else {
        const keys = Object.keys(filter);
        const blocks = keys.map(label => {
            const [isOpen, { toggle: toggleIsOpen }] = useBoolean(depth === 0);
            return {
                label,
                isOpen,
                toggleIsOpen,
                parent: parent ? parent + ':' + label : label
            }
        })
        return (
            <Stack className={styles.categoryBlock}>
                {blocks.map(b => {
                    return (
                        <Stack key={`${b.label}`} className={toClassArray(styles.categoryGroup, `depth-${depth}`)} onClick={b.toggleIsOpen}>
                            <Stack horizontal className={styles.category}>
                                <ReactSVG
                                    src={DownCaret}
                                    className={toClassArray(styles.caret, [b.isOpen, 'open'])}
                                    beforeInjection={(svg) => {
                                        svg.setAttribute('width', '1em');
                                        svg.setAttribute('height', '1em');
                                    }} />
                                <div className={styles.label}>{b.label}</div>
                            </Stack>
                            <Stack className={toClassArray(styles.filterlist, [b.isOpen, 'open'])}>
                                {filter[b.label].map(f => <DocFilter key={`${parent}-${f}`} filter={f} parent={b.parent} checkall={checkall} />)}
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
        )
    }
}

const toClassArray = (...cs: (string | undefined | [boolean, string])[]) =>
    cs.map(c => ['string', 'undefined'].some(x => x === typeof c) ? c : (c![0] ? c![1] : undefined)).filter(x => x).join(' ');
import { createTheme } from "@fluentui/react";

const accentTheme = createTheme({
    palette: {
      themePrimary: '#ed1c24',
      themeLighterAlt: '#fef5f6',
      themeLighter: '#fcd9da',
      themeLight: '#fab8ba',
      themeTertiary: '#f47378',
      themeSecondary: '#ef363c',
      themeDarkAlt: '#d51a20',
      themeDark: '#b4161b',
      themeDarker: '#851014',
      neutralLighterAlt: '#292b32',
      neutralLighter: '#282a31',
      neutralLight: '#26282f',
      neutralQuaternaryAlt: '#24252c',
      neutralQuaternary: '#22242a',
      neutralTertiaryAlt: '#212228',
      neutralTertiary: '#c8c8c8',
      neutralSecondary: '#d0d0d0',
      neutralPrimaryAlt: '#dadada',
      neutralPrimary: '#ffffff',
      neutralDark: '#f4f4f4',
      black: '#f8f8f8',
      white: '#2a2c33',
    }});

export default accentTheme;
import { useEffect, useRef, useState } from "react";
import { IRefObject, ITextField, ITextFieldSnapshot, Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import styles from "./QuestionInput.module.scss";
import { SettingsButton } from "../../components/SettingsButton";
import paperclip from "../../assets/upload.svg"
import send from "../../assets/send2.svg"
import { ReactSVG } from "react-svg";
interface Props {
    onSend: (question: string) => void;
    onTextChange: React.Dispatch<React.SetStateAction<string | undefined>>;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    tokensRemaining?: number;
    toggleConfigPannel: () => void
}



export const QuestionInput = ({ onSend, onTextChange, disabled, placeholder, clearOnSend, toggleConfigPannel, tokensRemaining }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const textfield = useRef<ITextField| undefined | null>(undefined); 
    useEffect(() => onTextChange(question), [question]);

    const sendQuestion = () => {
        if (sendQuestionDisabled) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onSendPress = (ev: React.MouseEvent<Element>) => {
        ev.stopPropagation();
        sendQuestion();
    }

    const onConfigToggle = (ev: React.MouseEvent<Element>) => {
        ev.stopPropagation();
        toggleConfigPannel();
    }

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim() || (tokensRemaining && tokensRemaining <= 0);

    return (
        <Stack horizontal className={styles.questionInputContainer} onClick={() => textfield.current?.focus()}>
            <div className={styles.questionInputOptionsButtonContainer}
                role="button"
                tabIndex={0}
                aria-label="Upload and attach"
                onClick={onConfigToggle}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleConfigPannel() : null}>
                    <ReactSVG src={paperclip} className={styles.questionInputOptionsButton} />
            </div>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                componentRef={(input) => textfield.current = input}
            />

            <div className={`${styles.questionInputSendButtonContainer} ${(sendQuestionDisabled ? styles.disabled : '')}`}
                role="button"
                tabIndex={0}
                aria-label="Ask question button"
                onClick={onSendPress}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                <ReactSVG src={send} className={styles.questionInputSendButton} />
            </div>
        </Stack>
    );
};

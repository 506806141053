import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from '@fortawesome/free-regular-svg-icons'
import styles from "./UserIcon.module.scss"
import { useMsal } from "@azure/msal-react";
import { graphRequest, loginRequest } from "../../authConfig";
import { Photo, getUserPhoto } from "../../api";
interface Props {

}

export const UserIcon = ({ className }: React.SVGAttributes<SVGElement> & Props) => {
    const [initials, setInitials] = useState<string | undefined>(undefined)
    const [img, setImg] = useState<Photo | undefined>(undefined)
    const { instance } = useMsal();
    useEffect(() => {
        {
            instance.acquireTokenSilent(loginRequest)
                .then(token => {
                    const claims = token.idTokenClaims;
                    const initials = (claims as any)?.initials as string | undefined;
                    if (initials) {
                        setInitials(initials)
                    }
                });
            instance.acquireTokenSilent(graphRequest)
                .then(token => token.accessToken)
                .then(getUserPhoto)
                .then(setImg)
        }
    }, []);

    if (img && img.value && img?.type) {
        return (
            <img className={className ?? styles.photo} src={URL.createObjectURL(img.value)} />
        )
    }
    else if (initials) {
        return (
            <div className={`${styles.initialsContainer}`}>
                <span className={styles.initials}>{initials}</span>
            </div>
        )
    }
    else {
        return (
            <FontAwesomeIcon icon={faUser} className={className} />
        )
    }
}
let onNewChat: (() => void) | undefined = undefined;

export const setNewChat = (newChat: () => void) => onNewChat = newChat;
export const clearNewChat = () => onNewChat = undefined;
export const hasNewChat = () => !!onNewChat;
export const callNewChat = () => {
    onNewChat?.call(this);
}

export const event = (() => {
    const events = new Set<React.Dispatch<React.SetStateAction<boolean>>>();
    const add = (event:React.Dispatch<React.SetStateAction<boolean>>) => {
        events.add(event);
    }
    const remove = (event: React.Dispatch<React.SetStateAction<boolean>>) => {
        events.delete(event);
    }
    const process = (value: boolean) => {
        events.forEach(e => e(value));
    }
    return {
        add, remove, process
    }
})();
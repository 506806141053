import { getDocumentFilters, DocumentFilter } from "../../api/api"
import { useContext, useEffect, useState } from "react";
import styles from "./DocumentFilters.module.scss";
import { DocFilter } from "./DocFilter";
import { OverridesContext } from "../../interfaces/overridesContextManagement";
interface Props {}
export const DocumentFilters = () => {
    const overridesState = useContext(OverridesContext);
    return (
        <article >
            <header className={styles.Banner}>
                <p>Chat results are drawn from the master document repository.</p>
                <p>The filters apply to main repository only, not to the temporarily uploaded files.</p>
            </header>
            <section className={styles.panel}>
                {overridesState.documentFilters ? (
                    <DocFilter filter={overridesState.documentFilters} />
                ) : (
                    <></>
                )}
            </section>
        </article>
    );
}
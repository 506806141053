import { IPivotItemProps, Panel, PanelType, Pivot, PivotItem } from "@fluentui/react";
import styles from "./ConfigurationPanel.module.scss";
import Uploader from "../uploader/uploader";
import { DocumentFilters } from "../DocumentFilters";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import Chevron from "../../assets/Left.svg"
import accentTheme from "../../fluidThemes/accentTheme";
import { ConfigurationSettings } from "../ConfigurationSettings/ConfigurationSettings";
import { isIn } from "../../utils/claimUtils";
import { Section } from "../../api";

interface Props {
    isConfigPanelOpen: boolean;
    selectedPivot: string;
    setSelectedPivot: React.Dispatch<React.SetStateAction<Section>>;
    currentText: string | undefined;
    setIsConfigPanelOpen: (state: boolean) => void;
    onSelectedFilesChanged: React.Dispatch<React.SetStateAction<string[]>>;
    onRemainingTokensChanged: React.Dispatch<React.SetStateAction<number>>;
    sessionId: string | undefined;
    setSessionId: (sessionId: string) => void;
    showSections: Section[] | undefined;
    defaultSection: Section | undefined;
}

export const ConfigurationPanel = ({
    isConfigPanelOpen,
    selectedPivot,
    setSelectedPivot,
    currentText,
    setIsConfigPanelOpen,
    onSelectedFilesChanged,
    onRemainingTokensChanged,
    sessionId,
    setSessionId,
    showSections,
    defaultSection
}: Props) => {

    const openAsDefault = () => {
        setSelectedPivot(defaultSection ?? "filters");
        setIsConfigPanelOpen(!isConfigPanelOpen);
    }

    const forcePivot = (item: PivotItem | undefined) => { item?.props.itemKey && setSelectedPivot(item.props.itemKey as Section); }

    return (
        <>
            <div className={`${styles.chatSessionsPanelHeader} ${isConfigPanelOpen ? "open" : ""}`} onClick={openAsDefault} title="Recent Activity">
                <ReactSVG src={Chevron} className={styles.chevron} />
            </div>
            <Panel
                headerClassName={styles.header}
                isOpen={isConfigPanelOpen}
                isBlocking={false}
                onDismiss={() => setIsConfigPanelOpen(false)}
                type={PanelType.custom}
                customWidth="450px"
                isHiddenOnDismiss={true}
                closeButtonAriaLabel="Close"
                isFooterAtBottom={true}
                className={styles.configuration}
                layerProps={{
                    style: {
                        zIndex: 10
                    },
                    styles: {
                        root: {
                            zIndex: 11
                        }
                    }
                }}
            >
                <Pivot aria-label="Search Configuration Options" className={styles.configurationpivot} selectedKey={selectedPivot} onLinkClick={forcePivot} theme={accentTheme}>
                    {(!showSections || isIn("uploader", ...showSections)) && (
                        <PivotItem headerText="Upload file" itemKey="uploader" style={{ 'display': 'flex', 'flexDirection': 'column', 'height': 'calc(100vh - 180px)' }}>
                            <Uploader
                                onSelectedFilesChanged={onSelectedFilesChanged}
                                onRemainingTokensChanged={onRemainingTokensChanged}
                                currentText={currentText}
                                sessionId={sessionId}
                                setSessionId={setSessionId} />
                        </PivotItem>
                    )}
                    {(!showSections || isIn("filters", ...showSections)) && (
                        <PivotItem headerText="Document filters" itemKey="filters">
                            <DocumentFilters />
                        </PivotItem>
                    )}
                    {(!showSections || isIn("config", ...showSections)) && (
                        <PivotItem headerText="Configuration" itemKey="config">
                            <ConfigurationSettings />
                        </PivotItem>
                    )}
                </Pivot>
            </Panel>
        </>
    );
};

import { createTheme } from "@fluentui/react";

const infoTheme = createTheme({
  palette: {
    themePrimary: '#96bcc5',
    themeLighterAlt: '#fafcfd',
    themeLighter: '#ecf4f6',
    themeLight: '#dceaed',
    themeTertiary: '#bcd6dc',
    themeSecondary: '#a0c4cb',
    themeDarkAlt: '#86a9b1',
    themeDark: '#718f95',
    themeDarker: '#54696e',
    neutralLighterAlt: '#4c4e5b',
    neutralLighter: '#525462',
    neutralLight: '#5c5e6d',
    neutralQuaternaryAlt: '#636574',
    neutralQuaternary: '#686a79',
    neutralTertiaryAlt: '#7f8190',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#444653',
  }});

  export const darkInfoTheme = createTheme({
    palette: {
      themePrimary: '#96bcc5',
      themeLighterAlt: '#060808',
      themeLighter: '#181e1f',
      themeLight: '#2d383b',
      themeTertiary: '#5a7176',
      themeSecondary: '#83a5ad',
      themeDarkAlt: '#9fc2ca',
      themeDark: '#accbd2',
      themeDarker: '#c0d9de',
      neutralLighterAlt: '#292b32',
      neutralLighter: '#282a31',
      neutralLight: '#26282f',
      neutralQuaternaryAlt: '#24252c',
      neutralQuaternary: '#22242a',
      neutralTertiaryAlt: '#212228',
      neutralTertiary: '#3a3a3b',
      neutralSecondary: '#737376',
      neutralPrimaryAlt: '#a9a9ad',
      neutralPrimary: '#c1c1c4',
      neutralDark: '#cfcfd2',
      black: '#dcdcde',
      white: '#2a2c33',
    }, isInverted: true});

  export default infoTheme;
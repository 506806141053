import { DocumentFilter } from "./api";

export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    answer_metadata?: AnswerMetadata
};

export type Citation = {
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type ChatMessage = {
    role: string;
    content: string;
    end_turn?: boolean;
    answer_metadata?: AnswerMetadata
};

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export interface AnswerMetadata {
    stats_tokens?: number;
    stats_found?: number;
    stats_filter?: string;
    news_tokens?: number;
    news_found?: number;
    news_filter?: string;
    docs_tokens?: number;
    docs_found?: number;
    docs_filter?: string;
    file_tokens?: number;
    data_source?: string;
    bounded?: boolean;
    cit_content?: {[k: string]: string | string[]};
}

export interface Prompts {
    queryPrompt?: string;
    querystructure?: {[k: string]: string}
    answerPrompt?: string;
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    error?: any;
    savedChat?: SavedChatResponse;
    prompts?: Prompts;
}

export type SavedChatResponse = {
    chat_id: string;
    title: string;
    created_timestamp: string;
    modified_timestamp: string;
    data?: string;
}

export type Dictionarise<T, prop extends keyof T & string> = {
    [k: string]: {
        [Property in keyof T as Exclude<Property, prop>]: T[Property]
    }
}

export type SavedChat = Dictionarise<SavedChatResponse, "chat_id">

export interface GPT_Overrides {
    deployment?: string;
    datasource?: string;
    documentFilters?: DocumentFilter;
    useNameSearch?: boolean;
    activeAppId?: string;
    draftMode?: boolean;
}

export type ConversationRequest = {
    messages: ChatMessage[];
    ids?: string[];
    chat_id?: string;
    overrides?: GPT_Overrides
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export type Section = "uploader" | "filters" | "config"

export type AppEntity = {
    PartitionKey: string;
    RowKey: string;
    title: string;
    prompt: string;
    model: string;
    categories: string[];
    description: string;
    created_timestamp: Date;
    modified_timestamp: Date;
    published_title?: string;
    published_prompt: string;
    published_model: string;
    published_categories: string[];
    published_description: string;
    created_by: string;
    modified_by: string;
}

export const AppEntityKeySet: (keyof AppEntity)[][] = [
    ["title", "published_title"],
    ["prompt", "published_prompt"],
    ["model", "published_model"],
    ["categories", "published_categories"],
    ["description", "published_description"]
]

export type TrainingEntity = {
    id: string
    [k: string]: string
}
import { deleteTraining, getTraining, postTraining, resetTraining, TrainingPayload, trainTraining } from "../../api/api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import authUserRole from "../../utils/AuthUserRole";
import { forwardRef, PropsWithChildren, PropsWithRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { TrainingEntity } from "../../api";
import { ContextualMenu, DefaultButton, Dropdown, IconButton, IDropdownOption, IIconProps, Modal, PrimaryButton, Stack, TextField, ThemeProvider } from "@fluentui/react";
import { toast } from 'react-toastify';
import darkTheme from "../../fluidThemes/darkTheme";
import styles from "./Training.module.scss";
import { SideBar } from "../../components/SideBar/SideBar";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridActionsCellItem, GridColDef, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid";
import { createPortal } from 'react-dom'
const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
};

const Training = () => {
    const { instance } = useMsal();
    const { isAdminUser, isNormalUser, isMediaUser } = authUserRole();

    const [hideDialog, { toggle: toggleHideDialog, setTrue: setHideDialog, setFalse: clearHideDialog }] = useBoolean(false);
    const [trainingData, setTrainingData] = useState<TrainingEntity[]>([]);

    const refreshTrainingData = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
        const data = await getTraining(token);
        setTrainingData(data);
    }

    const startTrainingData = async (should_refresh = true) => {
        toast.info("Training started")
        try {
            const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
            await trainTraining(token, should_refresh)
            await refreshTrainingData()
            toast.success("Training done")
        }
        catch (e) {
            console.log(e)
        }
    }

    const resetTrainingDataTrigger = () => {
        setHideDialog();
    }

    const confirmResetTrainingData = async () => {
        clearHideDialog();
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
        await resetTraining(token, true);
        toast.success("Training reset");
        await refreshTrainingData();
    }

    useEffect(() => {
        refreshTrainingData()
    }, [])

    const OnStartTraining = async () => {
        await startTrainingData(true);
        await refreshTrainingData();
    }

    const triggerTrainSingle = async () => {
        try {
            const data = await add_ref.current?.trigger();
            if (data) {
                const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
                toast.info("Training started");
                await postTraining(token, data);
                toast.success("Training done");
                await refreshTrainingData();
            }
        }
        catch (close_reason) {
            console.log(close_reason);
        }
    }

    const triggerDeleteTrainingSingle = async (id: string) => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
        await deleteTraining(token, {id: id});
        toast.success("Training Deleted");
        await refreshTrainingData();
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: dragOptions,
        }),
        [labelId, subTextId],
    );

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID" },
        { field: "question", headerName: "Question" },
        { field: "training_data_type", headerName: "Training Data Type" },
        { field: "content", headerName: "Content", minWidth: 500 },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams<TrainingPayload>) => [
              <GridActionsCellItem icon={<GridDeleteIcon />} onClick={() => triggerDeleteTrainingSingle(params.row.id!)} label="Delete" showInMenu={false} />
            ]
          }
    ]

    const add_ref = useRef<{ trigger: () => Promise<TrainingPayload> }>()

    return (
        <>
            <div className={styles.container} role="main">
                <SideBar>
                    <>
                        <div className={styles.info}>Train / Reset Data</div>
                        <DefaultButton onClick={OnStartTraining} text="Train Data" theme={darkTheme} />
                        <DefaultButton onClick={resetTrainingDataTrigger} text="Reset Data" theme={darkTheme} />
                        <PrimaryButton onClick={triggerTrainSingle} text="Add single training line" theme={darkTheme} />
                    </>
                </SideBar>
                <Stack>
                    <div className={styles.title}>Structured Chat Training Data</div>
                    <DataGrid
                            columns={columns}
                            className={styles.table}
                            rows={trainingData}
                            getRowId={(e: TrainingEntity) => e.id}
                            sx={{
                                height: '100%'
                            }}
                        />
                </Stack>
            </div>
            <ThemeProvider applyTo="body" theme={darkTheme}>
                <>
                    {createPortal((
                        <Modal
                            {...modalProps}
                            isOpen={hideDialog}
                            onDismiss={clearHideDialog}
                            containerClassName={styles.dialog}
                            isModeless={false}

                        >
                            <div className={styles.header}>
                                <h2 id="title">Confirm Reset</h2>
                                <IconButton
                                    className={styles.iconButtonStyles}
                                    iconProps={cancelIcon}
                                    ariaLabel="Close popup modal"
                                    onClick={clearHideDialog}
                                />
                            </div>
                            <div className={styles.dialogBody}>
                                <span>Are you sure you wish to reset the training data?</span>
                            </div>
                            <div className={styles.footer}>
                                <DefaultButton onClick={clearHideDialog} text="No" />
                                <div className={styles.separator}></div>
                                <PrimaryButton onClick={confirmResetTrainingData} text="Yes" />
                            </div>
                        </Modal>
                    ), document.getElementsByTagName('body')[0])}
                    <ModalAddTrainingForm ref={add_ref} />
                </>
            </ThemeProvider>
        </>
    )
}

export default Training;

const ModalAddTrainingForm = forwardRef((_, ref) => {
    const [training, setTraining] = useState<Partial<TrainingPayload>>();
    const resolver = useRef<(value: TrainingPayload | PromiseLike<TrainingPayload>) => void>();
    const rejecter = useRef<(reason?: any) => void>();
    useImperativeHandle(ref, () => ({
        trigger() {
            setHideDialog();
            return new Promise<TrainingPayload>((resolve, reject) => {
                resolver.current = resolve;
                rejecter.current = reject;
            }).finally(() => setTraining(undefined));
        }
    }));

    const validate_training = (training: Partial<TrainingPayload> | undefined): training is TrainingPayload => {
        return !!training && !!training.collection && !!training.content && (
            ['documentation', 'ddl'].indexOf(training.collection) > -1 ||
            (training.collection === 'sql' && !!training.question)
        )
    }

    const returnData = () => {
        clearHideDialog();
        if (validate_training(training)) {
            resolver.current && resolver.current(training);
        }
        else {
            rejecter.current && rejecter.current('empty');
        }
    }

    const closeModal = () => {
        clearHideDialog();
        rejecter.current && rejecter.current('closed');
    }

    const contentPlaceholder = useMemo(() => {
        switch (training?.collection) {
            case 'ddl': return 'Provide the DDL to be trained';
            case 'sql': return 'Provide valid SQL that answers the question';
            case 'documentation': return 'Provide a short description of the relevent information'
            default: ''
        }
    }, [training?.collection])

    const [hideDialog, { toggle: toggleHideDialog, setTrue: setHideDialog, setFalse: clearHideDialog }] = useBoolean(false);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: dragOptions,
        }),
        [labelId, subTextId],
    );

    const TrainingDropdowns: IDropdownOption[] = [
        { key: "ddl", text: "DDL" },
        { key: "documentation", text: "Documentation" },
        { key: "sql", text: "SQL" }
    ]

    const key_is_valid = (key: string | number | undefined): key is 'documentation' | 'ddl' | 'sql' | undefined => {
        return key === undefined || typeof (key) === 'string' && ['documentation', 'ddl', 'sql'].includes(key);
    }

    const onSetTrainingType = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, item?: IDropdownOption | undefined) => {
        const key = item?.key;
        if (!key_is_valid(key)) return;

        setTraining(prev => { return { ...prev, collection: key } })
    }

    const onSetContent = (content: string | undefined) => setTraining((prev) => { return { ...prev, content: content } });
    const onSetQuestion = (question: string | undefined) => setTraining((prev) => { return { ...prev, question: question } });
    return (
        <>
            {createPortal((
                <Modal
                    {...modalProps}
                    isOpen={hideDialog}
                    onDismiss={closeModal}
                    containerClassName={styles.dialog}
                    isModeless={false}
                >
                    <div className={styles.header}>
                        <h2 id="title">Add Training</h2>
                        <IconButton
                            className={styles.iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={closeModal}
                        />
                    </div>
                    <div className={styles.dialogBody}>
                        <Stack>
                            <Stack.Item>
                                <Dropdown
                                    placeholder="training type"
                                    className={styles.dropdown}
                                    label="Training Type"
                                    selectedKey={training?.collection}
                                    options={TrainingDropdowns}
                                    onChange={onSetTrainingType}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <TextField
                                    className={styles.panelControl}
                                    label="Content"
                                    placeholder={contentPlaceholder}
                                    value={training?.content}
                                    resizable={false}
                                    multiline={true}
                                    onChange={(_e, value: string | undefined) => onSetContent(value)}
                                />
                            </Stack.Item>
                            {(training?.collection === 'sql') &&
                                <Stack.Item>
                                    <TextField
                                        className={styles.panelControl}
                                        label="Question"
                                        placeholder={'Question'}
                                        value={training?.question}
                                        resizable={false}
                                        multiline={true}
                                        onChange={(_e, value: string | undefined) => onSetQuestion(value)}
                                    />
                                </Stack.Item>}
                        </Stack>
                    </div>
                    <div className={styles.footer}>
                        <DefaultButton onClick={closeModal} text="Cancel" />
                        <div className={styles.separator}></div>
                        <PrimaryButton onClick={returnData} text="Add Training" />
                    </div>
                </Modal>
            ), document.getElementsByTagName('body')[0])}
        </>
    )
})

import { DataGrid, 
  GridCallbackDetails, 
  GridColDef, 
  GridColumnHeaderParams, 
  GridFilterModel, 
  GridLogicOperator, 
  GridPaginationModel, 
  GridRenderCellParams, 
  GridRowSelectionModel, 
  GridTreeNodeWithRender, 
  ValueOptions, 
  useGridApiRef } from '@mui/x-data-grid';
import styles from "./DataTable.module.scss";
import { useEffect, useMemo, useState } from 'react'
import { IMetaData, IPageInfoBlock, getALLTags } from "../../api";
import { Button } from '@mui/material';
import pdf_icon from "../../assets/file_type_pdf_icon.svg";
import pptx_icon from "../../assets/file_type_powerpoint_icon.svg";
import docx_icon from "../../assets/file_type_word_icon.svg";
import txt_icon from "../../assets/file_type_text_icon.svg";
import excel_icon from "../../assets/file_type_excel_icon.svg";
import csv_icon from "../../assets/file_type_csv_icon.svg";
import doc_icon from "../../assets/file_type_doc_icon.svg";
import mp4_icon from "../../assets/file_type_mp4_icon.svg";
import wav_icon from "../../assets/file_type_wav_icon.svg";
import xls_icon from "../../assets/file_type_xls_icon.svg";
import xlsx_icon from "../../assets/file_type_xlsx_icon.svg";
import mp3_icon from "../../assets/file_type_mp3_icon.svg";
import { getFileExtension } from "../../utils/FilenameUtils";
import { DatePicker, Dropdown, IDropdownOption, PrimaryButton, ThemeProvider } from '@fluentui/react';
import { Default } from 'react-toastify/dist/utils';
import { Value } from 'sass';
import darkTheme from '../../fluidThemes/darkTheme';
import { defaultTheme } from '../../themes';
import { darkInfoTheme } from '../../fluidThemes/infoTheme';
import { constant } from 'lodash';

interface Items {
  Item: Item[];
  logicOperator: GridLogicOperator;
}
interface Item {
  id: number;
  field: string;
  operator: string;
  value: string;
}
interface DataTableProps {
  tableData: IMetaData[] | undefined;
  pageInfoBlock:(IPageInfoBlock|undefined);
  setPageInfoBlock:(pageInfoBlock:IPageInfoBlock)=>void;
  editRowData: (rowData: IMetaData) => void;
  selectRowId: (rowData: string) => void;
  allTags: ValueOptions[] | undefined;
  onFilterModelChange:(model: GridFilterModel | undefined) => Promise<void>;
  ctOptions: string[];
  allowedExtensions: string[];
  filestatusOptions: string[];
}

const formatTagArray = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>): JSX.Element => (<>{
  params?.value?.map((fv: string) => (<span className={styles.tag}>{fv}</span>)) ?? (<span>{params.formattedValue}</span>)
}</>)

export const DataTable = ({
  tableData,
  pageInfoBlock,
  setPageInfoBlock,
  editRowData,
  selectRowId,
  allTags,
  onFilterModelChange,
  ctOptions,
  allowedExtensions,
  filestatusOptions }: DataTableProps) => {

  const [rowId, setRowId] = useState();
  const [selectedCategories, setSelectedCategories] = useState<string[]>();
  const [selectedTypes, setSelectedTypes] = useState<string[]>();
  const [selectedTags, setSelectedTags] = useState<string[]>();
  const [selectedStatus, setSelectedStatus] = useState<string[]>();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      { id: 1, field: 'title', operator: 'contains', value: '' },
      { id: 2, field: 'description', operator: 'contains', value: '' },
      { id: 3, field: 'type', operator: 'contains', value: '' },
      { id: 4, field: 'category', operator: 'contains', value: '' },
      { id: 5, field: 'uploaded_by', operator: 'contains', value: '' },
      { id: 6, field: 'date_uploaded', operator: 'eq', value: '' },
      { id: 7, field: 'tagarray', operator: 'contains', value: '' },
      { id: 8, field: 'status', operator: 'contains', value: '' },
    ],
    logicOperator: GridLogicOperator.Or,
  });
  
  const [rowCount, setRowCount] = useState<number>(0);

  const gridApi = useGridApiRef();
  const handleGetRowId = (e: { id: any; }) => {
    return e.id
  }
  const categoryOptions = useMemo(()=> {
    return ctOptions.map(x => ({ key: x, text: x }));
  }, [ctOptions])
  const statusOptions = useMemo(()=>{
    return filestatusOptions.map(x => ({ key: x, text: x }));
  },[filestatusOptions])
  const typeOptions = useMemo(()=>{
    return allowedExtensions.map(x => ({ key: x, text: x }));
  },[allowedExtensions])

  const onAnyFilterChange = (field: string, event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const newfilterModel: GridFilterModel = { items: [], logicOperator: GridLogicOperator.And };
    newfilterModel.items = filterModel.items.map(item => {
      if (item.field == field)
        item.value = event.currentTarget.value;
      return item;
    })
    onFilterModelChange(newfilterModel);
  }
  const onDateFilterChange = (field: string, date?: Date) => {
    setSelectedDate(date);
    const newfilterModel: GridFilterModel = { items: [], logicOperator: GridLogicOperator.And };
    newfilterModel.items = filterModel.items.map(item => {
      if (item.field == field)
        item.value = onFormatSearchDate(date);
      return item;
    })
    onFilterModelChange(newfilterModel);
  }
  const onAnySelectChange = (field: string, event: React.FormEvent<HTMLElement>, ddItem: IDropdownOption | undefined) => {
    if (!ddItem) return "";
    const newfilterModel: GridFilterModel = { items: [], logicOperator: GridLogicOperator.And };
    newfilterModel.items = filterModel.items.map(item => {
      if (item.field == field) {
        item.operator = "in";
        switch (field) {
          case 'category':
            const catvalues = ddItem.selected ?
              [...selectedCategories ?? [], ddItem.key as string] :
              selectedCategories?.filter(key => key !== ddItem.key);

            item.value = catvalues?.join(",");
            setSelectedCategories(catvalues);
            break;
          case 'type':
            const typvalues = ddItem.selected ?
              [...selectedTypes ?? [], ddItem.key as string] :
              selectedTypes?.filter(key => key !== ddItem.key);

            item.value = typvalues?.join(",");
            setSelectedTypes(typvalues);
            break;
          case 'tags':
            const tagvalues = ddItem.selected ?
              [...selectedTags ?? [], ddItem.key as string] :
              selectedTags?.filter(key => key !== ddItem.key);

            item.value = tagvalues?.join(",");
            setSelectedTags(tagvalues);
            break;
          case 'status':
            const stavalues = ddItem.selected ?
              [...selectedStatus ?? [], ddItem.key as string] :
              selectedStatus?.filter(key => key !== ddItem.key);

            item.value = stavalues?.join(",");
            setSelectedStatus(stavalues);
            break;
          default:
            item.value = "";
            break;
        }
      }
      return item;
    })
    onFilterModelChange(newfilterModel);
  }
  const onFormatDate = (date?: Date): string => {
    return !date ? '' : ("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  const onFormatSearchDate = (date?: Date): string => {
    
    return !date ? '' : date.toDateString();
  };
  const columns: GridColDef[] = [
    {
      field: 'title', headerName: 'Name', flex: 1, minWidth: 300, hideSortIcons: true,
      renderCell: (cellValues) => {
        return <>
          {<img key={cellValues.row.id} src={getImageForExtension(getFileExtension(cellValues.row.title ?? ''))} className={styles.datagridIconImg} alt='' />}
          {cellValues.formattedValue ?? ""}
        </>
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Name</label>
          <input type='text' onClick={(e) => e.stopPropagation()} onChange={(e) => onAnyFilterChange(params.field, e)}></input></div>
      )
    },
    {
      field: 'description', headerName: 'Description', flex: 1, hideSortIcons: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Description</label>
          <input onClick={(e) => e.stopPropagation()} onChange={(e) => onAnyFilterChange(params.field, e)} type='text'></input></div>
      )
    },
    {
      field: 'type', headerName: 'Type', flex: 1, hideSortIcons: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Type</label>
          <ThemeProvider applyTo="body" theme={darkTheme}>
            <Dropdown
              className={styles.dropdown}
              multiSelect={true}
              options={typeOptions}
              onClick={(e) => e.stopPropagation()}
              onChange={((t, value) => {
                onAnySelectChange(params.field, t, value);
              })}
            />
          </ThemeProvider>
        </div>
      )
    },
    {
      field: 'category', headerName: 'Category', flex: 1, hideSortIcons: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Category</label>
          <ThemeProvider applyTo="body" theme={darkTheme}>
            <Dropdown
              className={styles.dropdown}
              multiSelect={true}
              options={categoryOptions}
              onClick={(e) => e.stopPropagation()}
              onChange={((t, value) => {
                onAnySelectChange(params.field, t, value);
              })}
            />
          </ThemeProvider>
        </div>)
    },
    {
      field: 'uploaded_by', headerName: 'Uploaded By', flex: 1, hideSortIcons: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Uploaded By</label>
          <input onClick={(e) => e.stopPropagation()} onChange={(e) => onAnyFilterChange(params.field, e)}
            type='text'></input>
        </div>
      )
    },
    {
      field: 'date_uploaded', type: 'date', headerName: 'Date Uploaded', flex: 1, hideSortIcons: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Date Uploaded</label>
          <ThemeProvider applyTo="body" theme={darkTheme}>
            <DatePicker
              className={styles.datepicker}
              onSelectDate={(e) =>
                onDateFilterChange(params.field, e ?? undefined)
              }
              textField={{
                onRenderPrefix: () => <input type='button' value='x' className={styles.datepickerclear} 
                onClick={(e)=>{e.stopPropagation(); onDateFilterChange(params.field, undefined);}}/>                 
              }}
              formatDate={onFormatDate}
              value={selectedDate}
            />
          </ThemeProvider>
        </div>
      ), valueFormatter: (value, _row, _col, _apiRef) =>
        (new Date(Date.parse(value))).toLocaleDateString(),
    },
    {
      field: 'tagarray', type: 'singleSelect', headerName: 'Tags', flex: 1, hideSortIcons: true, 
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Tags</label>
          <input type='text' onClick={(e) => e.stopPropagation()} onChange={(e) => onAnyFilterChange(params.field, e)}></input>
        </div>
      ), valueOptions: allTags, renderCell: formatTagArray
    },
    {
      field: 'status', headerName: 'Status', flex: 1, hideSortIcons: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}>
          <label>Status</label>
          <ThemeProvider applyTo="body" theme={darkTheme}>
            <Dropdown
              className={styles.dropdown}
              multiSelect={true}
              options={statusOptions}
              onClick={(e) => e.stopPropagation()}
              onChange={((t, value) => {
                onAnySelectChange(params.field, t, value);
              })}
            /></ThemeProvider>
        </div>
      )
    },
    {
      field: "Edit",
      headerName: "Edit",
      sortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        const handleClick = () => {
          editRowData(params.row);
        };
        return (
          <PrimaryButton 
            id={`btnClick_${params.row.id}`}
            itemID='btnClick' 
            key={params.row.id} 
            onClick={handleClick}
            theme={darkTheme}
          >
            Edit
          </PrimaryButton>
        )
      },
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className={styles.gridHeader}></div>)
    },
  ];

  const onRowSelectionModelChange = (data: GridRowSelectionModel) => {
    const id = data;
    const titles = tableData?.filter(t => data.some(d => d === t.id)).map(t => t.title);
    selectRowId(titles?.toString() ?? "");
  }
  const onPaginationModelChange = async (pageModel: GridPaginationModel) => {
    const id = pageModel;
    const newPageInfo:IPageInfoBlock={
      page:pageModel.page,
      pageSize:pageModel.pageSize,
      numEntries:pageInfoBlock?.numEntries??0,
      numEntriesonCurrentPage:pageInfoBlock?.numEntriesonCurrentPage??0,
      numPages:pageInfoBlock?.numPages??0
    };
    setPageInfoBlock(newPageInfo);    
  }
  const getImageForExtension = (extension: string) => {
    switch (extension) {
      case 'pdf': return pdf_icon;
      case 'doc': return doc_icon;
      case 'docx': return docx_icon;
      case 'pptx': return pptx_icon;
      case 'txt': return txt_icon;
      case 'xls': return xls_icon;
      case 'xlsx': return xlsx_icon;
      case 'csv': return csv_icon;
      case 'wav': return wav_icon;
      case 'mp3': return mp3_icon;
      case 'mp4': return mp4_icon;
      default: return txt_icon;
    }
  }
  useEffect(()=>{
    setRowCount(pageInfoBlock?.numEntries??0);
  },[pageInfoBlock])
  if (!tableData) {
    return <></>
  }
  else {
    return (
      <div className={styles.gridParent}>
        <DataGrid
          apiRef={gridApi}
          columns={columns}
          filterMode='server'
          paginationMode='server'
          onFilterModelChange={onFilterModelChange}
          getRowId={handleGetRowId}
          disableRowSelectionOnClick
          disableColumnFilter
          className={styles.table}
          checkboxSelection
          rowCount={rowCount}           
          pageSizeOptions={[5, 10, 25]}
          paginationModel={pageInfoBlock}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            onRowSelectionModelChange(newRowSelectionModel);
          }}
          onPaginationModelChange ={(page)=>{onPaginationModelChange(page)}}
          sx={{
            boxShadow: 0,
            color: 'var(--text-white)',
            fontFamily: defaultTheme.fonts.medium.fontFamily,
            fontSize: defaultTheme.fonts.medium.fontSize,
            fontWeight: defaultTheme.fonts.medium.fontWeight,
            border: 0
          }}
          rows={tableData}
        />
      </div>
    );
  }
}


import { NavLink, Route, Routes, useLocation } from "react-router-dom";

import styles from "./SideBarItem.module.scss";
import { style } from "@mui/system";
interface Props {
  name: string;
  route: string;
  match_at_root?: boolean
}

const GetNavLink = ({name, route}: Props) => {
  return (
    <NavLink
      to={`${route}`}
      className={({ isActive }) => isActive ? `${styles.sidebaritem} ${styles.active}` : `${styles.sidebaritem} `}
    >
      {name}
    </NavLink>
  );
}

export const SideBarItem = ({ name, route, match_at_root = false }: Props) => {
  let location = useLocation();    
  if(match_at_root){
    if (location.pathname === route) return <GetNavLink name={name} route={route} />
    return <GetNavLink name={name} route={'/'} />
  }
  else {
    return <GetNavLink name={name} route={route} />
  }
};

import { AskResponse, Citation } from "../../api";
import { cloneDeep } from "lodash-es";


type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {
    let answerText = answer.answer;
    const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

    const lengthDocN = "[doc".length;

    let filteredCitations = [] as Citation[];
    let citationReindex = 0;
    citationLinks?.forEach(link => {
        // Replacing the links/citations with number
        let citationIndex = link.slice(lengthDocN, link.length - 1);
        let citation = cloneDeep(answer.citations[Number(citationIndex) - 1]) as Citation;
        if (!filteredCitations.find((c) => c.id === citationIndex)) {
          answerText = answerText.replaceAll(link, ` ^${++citationReindex}^ `);
          citation.id = citationIndex; // original doc index to de-dupe
          citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
          filteredCitations.push(citation);
        }
    })


    return {
        citations: filteredCitations,
        markdownFormatText: answerText
    };
}

export const getAndReplaceCitationFromResponse = (answer: AskResponse ): ParsedAnswer =>  {
    const cit_re = /\{\{(?<title>[^}|]+)(?:\|(?<url>[^}]+))?\}\}/g;
    const citations: Citation[] = [];
    const answerText = answer.answer
    let index = 0;
    const markdownFormatText = answerText.replace(cit_re, (...g) => {
        const id = ++index;
        const {title, url} = {...g.at(-1)} as {title: string, url?: string};
        const content = answer.answer_metadata?.cit_content?.[title]
        if (content === undefined) {
            citations.push({id, content: '', ...g.at(-1)});
        }
        else if (typeof content === "string"){
            citations.push({id, content: content, ...g.at(-1)});
        }
        else {
            citations.push({id, content: content.join('<br><hr><br>'), ...g.at(-1)});
        }
        
        return `^[${id}]^`;
    });
    
    return {
        markdownFormatText,
        citations
    };
}

let onTogglePanel: (() => void) | undefined = undefined;

export const setTogglePanel = (togglePanel: () => void) => onTogglePanel = togglePanel;
export const clearTogglePanel = () => onTogglePanel = undefined;
export const hasTogglePanel = () => !!onTogglePanel;
export const callTogglePanel = () => {
    onTogglePanel?.call(this);
}

export const event = (() => {
    const events = new Set<React.Dispatch<React.SetStateAction<boolean>>>();
    const add = (event:React.Dispatch<React.SetStateAction<boolean>>) => {
        events.add(event);
    }
    const remove = (event: React.Dispatch<React.SetStateAction<boolean>>) => {
        events.delete(event);
    }
    const process = (value: boolean) => {
        events.forEach(e => e(value));
    }
    return {
        add, remove, process
    }
})();
import { Modal } from "@fluentui/react";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import styles from "./Spinner.module.scss";
import { useState } from "react";

interface SpinnerProps {
    message: string | undefined;
    showSpinner: boolean;
}
export const SpinnerUI = ({ message = "Loading...", showSpinner }: SpinnerProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>();
    const HideSpinner = () => {
        setOpenDialog(false);
    }
    const ShowSpinner = () => {
        setOpenDialog(true);
    }

    return (
        <Modal
            isOpen={showSpinner}
            onDismiss={() => { }}
            containerClassName={styles.spinnerContainer}
            isModeless={false}>

            <div className={styles.spinnerBody}>

                <Spinner size={SpinnerSize.large} />
                <div className={styles.spinnerMessage}>{message}</div>
            </div>
        </Modal >
    );
}
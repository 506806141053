import * as React from "react";
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { Modal, IDropdownOption, ThemeProvider, ContextualMenu, IDragOptions, IIconProps } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import styles from "./BulkEditDocument.module.scss";
import darkTheme from "../../fluidThemes/darkTheme";
import { DialogContent } from "@mui/material";
import { ComboBox, TextField, Dropdown } from "@fluentui/react";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { useEffect, useState } from "react";
import { getCategoriesFromAPI, Updates, BulkUpdates, Update, editDocumentAPI } from "../../api";
import { toast } from "react-toastify";
import { useOktaAuth } from "@okta/okta-react";
import { TagpickerComponent } from "../TagpickerComponent/TagpickerComponent";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

import { title } from "process";

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const modalPropsStyles = { main: { minWidth: 550, Width: 550 } };
const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

interface BulkEditDocumentProps {
  rowIds: string | undefined;
  reloadPage: () => Promise<void>;
  disabled: boolean;
  setShowSpinner: (show: boolean) => void;
}

export const BulkEditDocument = ({ rowIds, reloadPage, disabled = false, setShowSpinner }: BulkEditDocumentProps) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const [ctOptions, setCategoryOptions] = useState<IDropdownOption[]>([]);
  // const { oktaAuth, authState } = useOktaAuth();
  const { instance } = useMsal();

  const [selectedCategory, setSelectedCategory] = useState<string|undefined>(undefined);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [selectedMode, setSelectedMode] = useState<string|undefined>(undefined);
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles
    }),
    [isDraggable],
  );
  const choices: IChoiceGroupOption[] = [
    { key: 'append', text: 'Append' },
    { key: 'replace', text: 'Replace' },
    { key: 'remove', text: 'Remove' },
  ];

  const getCategoryFromApi = async () => {
    try {
      const ct = await getCategoriesFromAPI();
      if (ct) {
        updateCategories(ct);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const updateCategories = async (ct: string) => {
    const categories = ct.split(",").map(c => {
      return { key: c.trim(), text: c.trim() };
    });
    setCategoryOptions(categories);
  };

  useEffect(() => {
    getCategoryFromApi();
  }, []);


  const editDocument = async (category: string|undefined, tags: string[], mode: string|undefined) => {
    try {
      setShowSpinner(true);
      const token = (await instance.acquireTokenSilent(loginRequest)).accessToken

      if (!rowIds || rowIds.length === 0) {
        throw new Error('rowIds is undefined or empty');
      }

      const rowIdsArray = rowIds.split(',').map(id => id.trim());

      const updates = rowIdsArray.map(title => {
        return {
          title,
          category,
          tags,
          mode
        };
      });

      const updatesData: BulkUpdates = {
        updates,
      };

      const response = await editDocumentAPI(updatesData, token);

      if (response) {

        toast.success("Documents edited successfully.");

        setShowSpinner(false);
        
        resetModal();
        await reloadPage();
      }
    } catch (error) {
      const err: Error | AggregateError = error as any;
      toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false });
    } finally {
      toggleHideDialog();

    }
  };

const resetModal = () => {
  setSelectedCategory(undefined);
  setSelectedTags([]);
  setSelectedMode(undefined);
}

const dismissModal = () => {
  resetModal();
  toggleHideDialog();
}


  return (
    <>
      <PrimaryButton disabled={disabled} theme={darkTheme} className={styles.alternativebutton} secondaryText="Bulk Edit" onClick={toggleHideDialog} text="Bulk Edit" />
      <ThemeProvider applyTo="body" theme={darkTheme}>
        <Modal
          isOpen={hideDialog}
          onDismiss={dismissModal}
          containerClassName={styles.dialog}
          isModeless={false}
          dragOptions={isDraggable ? dragOptions : undefined}
        >
          <div className={styles.header}>
            <h2 className={styles.heading} id={"title"}>
              Bulk Edit
            </h2>
            <IconButton
              className={styles.iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={toggleHideDialog}
            />
          </div>
          <div className={styles.dialogBody}>
            <div className={styles.sectionBody}>
              <span className={styles.heading}>Category</span>
              <Dropdown
                className={styles.panelControl}
                placeholder={"Select Category"}
                options={ctOptions}
                selectedKey={selectedCategory}
                onChange={(event, option) => setSelectedCategory(option?.key as string)}
              />
            </div>
            <div className={styles.sectionBody}>
              <span className={styles.heading}>Tags</span>
              <TagpickerComponent
                setSelectedTags={setSelectedTags}
                selectedTags={selectedTags}
              />
            </div>
            <div className={styles.sectionBody}>
              <span className={styles.heading}>Mode</span>
              <ChoiceGroup
                selectedKey={selectedMode}
                options={choices}
                onChange={(ev, option) => setSelectedMode(option?.key as string)}
              />
            </div>
            <PrimaryButton text="Save" theme={darkTheme} className={`${styles.savebutton} ${styles.alternativebutton}`} onClick={() => editDocument(selectedCategory, selectedTags, selectedMode)} />
          </div>
        </Modal>
      </ThemeProvider>
    </>
  );

};
import { useNavigate } from "react-router-dom";
import { AppEntity, AppEntityKeySet } from "../../api";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { DefaultButton } from "@fluentui/react";
import darkTheme from "../../fluidThemes/darkTheme";
import { Box, Typography, Popover } from "@mui/material";
import { useState } from "react";
import styles from "./AppSelector.module.scss";


interface Props {
    entities: AppEntity[];
    rowKey: string | undefined;
}

const AppSelector = ({ entities, rowKey }: Props) => {
    const navigate = useNavigate()
    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'App',
            flex: 1,
            headerClassName: styles.header
        },
        {
            field: 'categories',
            headerName: 'Categories',
            renderCell: (params) => params.value?.length ?? 'none',
            getApplyQuickFilterFn: undefined,
            width: 75,
            headerClassName: styles.header
        },
        {
            field: 'ispublished',
            headerName: '',
            type: 'boolean',
            getApplyQuickFilterFn: undefined,
            valueGetter: (_, row) => AppEntityKeySet.every(k => row[k[1]] !== undefined),
            width: 5,
            headerClassName: styles.header
        }
    ]
    const QuickSearchToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 0.5, pb: 0, }}>
                <GridToolbarQuickFilter sx={{ flex: 1, paddingRight: '5px' }} />
                <DefaultButton
                    theme={darkTheme}
                    onClick={() => navigate('../', { relative: 'route' })}
                    disabled={!rowKey}
                >
                    Add New
                </DefaultButton>
            </GridToolbarContainer>
        );
    }
    const [popoverValue, setPopoverValue] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        const field = event.currentTarget.dataset.field;
        const id = event.currentTarget.parentElement!.dataset.id;
        const row = entities.find(e => e.RowKey === id);
        let val: string[] = []
        switch (field) {
            case 'categories': val = row?.categories ?? []; break;
            case 'title': val = [row?.title].filter(t => t !== undefined); break;
            case 'ispublished': val = [AppEntityKeySet.every(k => row![k[1]] !== undefined) ? 'Published' : 'Draft']
            default: break;
        }
        if (val && val.length > 0) {
            setPopoverValue(val);
            setAnchorEl(event.currentTarget)
        }
    }
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <DataGrid
                columns={columns}
                rows={entities}
                getRowId={(e: AppEntity) => e.RowKey}
                sx={{
                    height: 410,
                    fontSize: '.8rem'
                }}
                disableColumnMenu
                disableColumnResize={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }]
                    },
                    pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                    }
                }}
                density="compact"
                onRowClick={({ id }) => navigate(`../${id}`, { relative: 'route' })}
                hideFooterSelectedRowCount={true}
                pageSizeOptions={[10]}
                slots={{ toolbar: QuickSearchToolbar }}
                slotProps={{
                    cell: {
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    },
                }}
                getRowClassName={() => styles.row}
            />
            <Popover
                sx={{
                    pointerEvents: 'none'
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ p: 1 }}>
                    {popoverValue.map(p =>
                        <Typography key={p}>{p}</Typography>
                    )}
                </Box>
            </Popover>
        </>
    )
}

export default AppSelector;

import { DefaultButton, Dropdown, IDropdownOption, PrimaryButton, Stack, TextField, ThemeProvider } from "@fluentui/react";
import { SideBar } from "../../components/SideBar/SideBar";
import styles from "./AppCreator.module.scss";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OverridesContext } from "../../interfaces/overridesContextManagement";
import { AppEntity, AppEntityKeySet, createApp, getApp, getAppsStream, getDocumentFilters, getOpenAIDeployments, updateApp } from "../../api";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify"
import { loginRequest } from "../../authConfig";
import { Link, useParams, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import darkTheme from "../../fluidThemes/darkTheme";
import { Box, Popover, Typography } from "@mui/material";
import { useBoolean } from "@fluentui/react-hooks"
import AppSelector from "./AppSelector";
import { compare } from "../../utils/comparisonUtils";

const AppCreator = () => {
    const { instance } = useMsal();
    const overridesState = useContext(OverridesContext);
    const [modelChoices, setModelChoices] = useState<IDropdownOption[]>([]);
    const [catChoices, setCatChoices] = useState<IDropdownOption[]>([]);
    const [entity, setEntity] = useState<Partial<AppEntity>>({});
    const [entities, setEntities] = useState<AppEntity[]>([]);
    const isNew = useMemo(() => entity.RowKey === undefined, [entity]);
    const isValid = useMemo(() => entity.title && entity.prompt, [entity])
    const abortFuncs = useRef([] as AbortController[]);
    const { rowKey } = useParams();
    const navigate = useNavigate();

    const isLoaded = useMemo(() => rowKey === undefined || rowKey === entity.RowKey, [rowKey, entity])
    const [isProcessing, { setTrue: startProc, setFalse: endProc }] = useBoolean(false);
    const formDisabled = () => !isLoaded || isProcessing;

    const getOpenAIDeploymentsFromApi = async () => {
        const deployments = await getOpenAIDeployments();
        if (deployments) {
            overridesState.setOpenAIDeployments(deployments);
            const defaultDeployment = deployments.find(d => d.default);
            overridesState.setSelectedDeployment(overridesState.selectedDeployment ?? defaultDeployment ?? deployments[0])
        }
    }

    const getDocumentFiltersFromAPI = async () => {
        getDocumentFilters(['category', 'tags', 'type'])
            .then(f => overridesState.setDocumentFilters(f))
            .catch(console.log);
    }

    useEffect(() => {
        setModelChoices(overridesState.openAIDeployments.toSorted((a, b) => a.order - b.order).map(d => {
            return {
                key: d.deployment,
                text: d.deployment
            } as IDropdownOption
        }));
    }, [overridesState.openAIDeployments, entity]);

    useEffect(() => {
        setCatChoices(overridesState.documentFilters?.['category']?.map(c => {
            return {
                key: c,
                text: (c[0].toUpperCase() + c.slice(1).toLocaleLowerCase())
            } as IDropdownOption;
        }) ?? []);
    }, [overridesState.documentFilters, entity]);

    useEffect(() => {
        Promise.all([
            fetchApps(),
            getOpenAIDeploymentsFromApi(),
            getDocumentFiltersFromAPI(),
        ]);
    }, [])

    useEffect(() => {
        if (rowKey) {
            instance.acquireTokenSilent(loginRequest)
                .then(t => t.accessToken)
                .then(token => getApp(rowKey, token))
                .then(setEntity)
        }
        else {
            setEntity({})
        }
    }, [rowKey])

    const fetchApps = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
        const utf8Decoder = new TextDecoder("utf-8")
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try {
            const response = await getAppsStream(token, abortController.signal)
            if (response?.body) {
                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    var text = utf8Decoder.decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            const data: { sessions: AppEntity[] } = JSON.parse(runningText);
                            setEntities(data.sessions);
                            runningText = "";
                        }
                        catch { }
                    });
                }
            }
        }
        catch (error) {
            console.error('Error fetching apps: ', error);
        }
    }

    const addApp = async () => {
        startProc();
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
        try {
            const app = await createApp(entity as AppEntity, token);
            setEntity(app);
            await fetchApps();
            toast.success(`App ${app.title} created`);
            navigate(app.RowKey, { relative: 'route' })
        }
        catch (error) {
            const err: Error | AggregateError = error as any;
            toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false })
        }
        finally {
            endProc();
        }
    }

    const [cueSave, setCueSave] = useState(false);
    const saveEditApp = async () => {
        startProc();
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
        try {
            await updateApp(entity as AppEntity, token);
            await fetchApps();
            toast.success(`App ${entity.title} edited`)
        }
        catch (error) {
            const err: Error | AggregateError = error as any;
            toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false })
        }
        finally {
            endProc();
        }
    }

    useEffect(() => {
        if (cueSave) {
            setCueSave(false);
            saveEditApp();
        }
    }, [cueSave, entity]);

    const hasChanges = useMemo(() => AppEntityKeySet.some(k => !compare(entity[k[0]], entity[k[1]])), [entity]);
    const draftComplete = useMemo(() => AppEntityKeySet.every(k => entity[k[0]] !== undefined), [entity]);
    const isPublished = useMemo(() => AppEntityKeySet.every(k => entity[k[1]] !== undefined), [entity]);

    const canPublish = useMemo(() => hasChanges && draftComplete, [entity]);
    const canRevert = useMemo(() => hasChanges && isPublished, [entity])

    const publishApp = () => {
        const published: Partial<AppEntity> = {
            published_title: entity.title,
            published_prompt: entity.prompt,
            published_model: entity.model,
            published_description: entity.description,
            published_categories: entity.categories
        }
        setEntity(e => {
            return { ...e, ...published }
        });
        setCueSave(true);
    }

    const unpublishApp = () => {
        const unpublished: Partial<AppEntity> = {
            published_title: undefined,
            published_prompt: undefined,
            published_model: undefined,
            published_description: undefined,
            published_categories: undefined
        }
        setEntity(e => {
            return { ...e, ...unpublished }
        });
        setCueSave(true);
    }

    const revertApp = () => {
        const reverted: Partial<AppEntity> = {
            title: entity.published_title,
            prompt: entity.published_prompt,
            model: entity.published_model,
            description: entity.published_description,
            categories: entity.published_categories
        }
        setEntity(e => {
            return { ...e, ...reverted }
        });
        setCueSave(true);
    }

    const submit = async () => isNew ? addApp() : saveEditApp();

    const onSelectCategory = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, item?: IDropdownOption | undefined) => {
        const key = item?.key;
        if (!key) return;
        setEntity(prev => {
            const curr_cats = prev.categories ?? [];
            if (item?.selected) {
                curr_cats.push(key.toString())
            }
            else {
                const ix = curr_cats.indexOf(key.toString())
                if (ix > -1) {
                    curr_cats.splice(ix, 1)
                }
            }
            return { ...prev, categories: curr_cats }
        })
    }

    const onSelectModel = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, item?: IDropdownOption | undefined) => {
        const key = item?.key;
        if (!key) return;
        const deployment = overridesState.openAIDeployments.find(d => d.deployment === key);
        if (!deployment) return;
        setEntity(prev => { return { ...prev, model: deployment.deployment } })
    }

    const onChange = <K extends keyof AppEntity>(field: K) => (_event: any, value: AppEntity[K] | undefined) => {
        const obj: Partial<AppEntity> = {}
        obj[field] = value
        setEntity(prev => { return { ...prev, ...obj } })
    }

    

    return <>
        <ThemeProvider theme={darkTheme} className={styles.container} role="main">
            <SideBar>
                <>
                    <section className={styles.panel}>
                        <header>
                            <h4>Edit existing</h4>
                        </header>
                        <AppSelector entities={entities} rowKey={rowKey} />
                    </section>
                </>
            </SideBar>
            <Stack>
                <div className={styles.title}>Create an App</div>
                <Stack horizontal className={styles.appCreateRoot}>
                    <Stack className={`${styles.builder} ${(!isLoaded ? styles.noloaded : '')}`}>
                        <Stack.Item>
                            <TextField
                                className={styles.panelControl}
                                label="Title"
                                placeholder={"Untitled App"}
                                value={entity.title || ''}
                                resizable={false}
                                multiline={false}
                                onChange={onChange('title')}
                                disabled={formDisabled()}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                className={[styles.panelControl, styles.prompt].join(' ')}
                                label="Prompt"
                                placeholder={"You are an AI assistant that helps people find information."}
                                value={entity.prompt || ''}
                                resizable={true}
                                multiline={true}
                                onChange={((_e, value) => { setEntity(prev => { return { ...prev, prompt: value } }) })}
                                disabled={formDisabled()}
                            />
                            <div className={styles.append}>
                                The date today is {"{date}"}<br />
                                Source: {"{sources}"}<br />
                                Files: {"{files}"}
                            </div>
                            <p>The prompt suffix above is added to each prompt, to allow it to identify the date, and read the source or added files.</p>
                        </Stack.Item>
                        <Stack.Item>
                            <Dropdown
                                placeholder="Select a model"
                                className={styles.dropdown}
                                label="GPT Model"
                                selectedKey={entity.model || null}
                                options={modelChoices}
                                onChange={onSelectModel}
                                disabled={formDisabled()}
                                theme={darkTheme}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Dropdown
                                placeholder="Select a datasource (category)"
                                className={styles.dropdown}
                                label="Document Category"
                                selectedKeys={entity.categories || null}
                                options={catChoices}
                                multiSelect={true}
                                onChange={onSelectCategory}
                                disabled={formDisabled()}
                                theme={darkTheme}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                className={styles.panelControl}
                                label="Description"
                                placeholder={"Provide a short description of your app."}
                                value={entity.description || ''}
                                resizable={true}
                                multiline={true}
                                onChange={((_e, value) => { setEntity(prev => { return { ...prev, description: value } }) })}
                                disabled={formDisabled()}
                            />
                        </Stack.Item>
                        <Stack.Item style={{ paddingTop: '5px', display: 'flex', flexDirection: 'column' }}>
                            <PrimaryButton onClick={submit} disabled={(!isValid || formDisabled())} className={styles.controlButton} theme={darkTheme}>
                                {isNew ? "Add App" : "Save changes"}
                            </PrimaryButton>
                        </Stack.Item>
                        <Stack.Item style={{ paddingTop: '5px' }}>
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                <PrimaryButton
                                    className={[styles.controlButton, styles.publishApp].join(' ')}
                                    theme={darkTheme}
                                    disabled={!canPublish || formDisabled()}
                                    onClick={publishApp}
                                    text="Publish"
                                />
                                <PrimaryButton
                                    className={[styles.controlButton, styles.publishApp].join(' ')}
                                    theme={darkTheme}
                                    disabled={!isPublished || formDisabled()}
                                    onClick={unpublishApp}
                                    text="Unpublish" />
                                <Box sx={{ flexGrow: 1 }} />
                                <PrimaryButton
                                    className={[styles.controlButton, styles.revertApp].join(' ')}
                                    theme={darkTheme}
                                    disabled={!canRevert || formDisabled()}
                                    onClick={revertApp}
                                    text="Revert To Draft" />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <Stack className={styles.tester}></Stack>
                </Stack>
            </Stack>
        </ThemeProvider>
    </>
};

export default AppCreator;
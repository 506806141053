import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import styles from "./ConfigurationSettings.module.scss";
import { OverridesContext } from "../../interfaces/overridesContextManagement";
import { ChoiceGroup, IChoiceGroupOption, Stack } from "@fluentui/react";
import { ReactSVG } from "react-svg";
import ArrowDown from "../../assets/Down.svg";
import ArrowUp from "../../assets/Up.svg";
import { Switch, SwitchOnChangeData } from "@fluentui/react-components";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { AppEntityKeySet, getTestConnection, getIsAppActive } from "../../api";
import { Id, toast } from "react-toastify";
import AppSelector from "../ConfigurationAppSelector/AppSelector";
import { compare } from "../../utils/comparisonUtils";
import authUserRole from "../../utils/AuthUserRole";

const defaultSelectedDataSource = 'none'

export const ConfigurationSettings = () => {
    const overridesState = useContext(OverridesContext);
    const [choices, setChoices] = useState<IChoiceGroupOption[]>([])
    const [connectionTest, setConnectionTest] = useState<boolean>(false);
    const [appDescriptionOpen, { toggle: toggleAppDescriptionOpen }] = useBoolean(false);
    const [appMode, { toggle: toggleAppMode }] = useBoolean(true);
    const [activeApps, setActiveApps] = useState<string[]>([]);
    const [modelDescriptionOpen, { toggle: toggleModelDescriptionOpen }] = useBoolean(false);
    const [datasourceDescriptionOpen, { toggle: toggleDatasourcelDescriptionOpen }] = useBoolean(false);
    const { isAdminUser, isNormalUser, isMediaUser, isTestingUser } = authUserRole();
    const toastWarningId = useRef<Id>()
    const onSelectModel = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        const key = option?.key;
        if (!key) return;
        const deployment = overridesState.openAIDeployments.find(d => d.deployment === key);
        if (!deployment) return;
        overridesState.setSelectedDeployment(deployment);
    }

    const onSelectDS = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
        const key = option?.key;
        overridesState.setSelectedDataSource(key ?? defaultSelectedDataSource);
        if (key !== 'aflstruct') {
            overridesState.setUseNamesSearch(false)
        }
    }

    const onSwitchLogPrompts = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, data?: SwitchOnChangeData | undefined) => {
        const ischecked = data?.checked ?? false;
        overridesState.setLogPrompts(ischecked);
    }

    const onSwitchNamesSearch = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, data?: SwitchOnChangeData | undefined) => {
        const ischecked = data?.checked ?? false;
        overridesState.setUseNamesSearch(ischecked);
    }

    const { instance } = useMsal();
    const TestConnection = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken;
        const { connection_test } = await getTestConnection(token);
        setConnectionTest(connection_test);
        if (!connection_test && (!toastWarningId.current || toast.isActive(toastWarningId.current))) {
            toastWarningId.current = toast.warn("Structured Data cannot connect to the data store. This option is currently disabled",)
        }
    }

    useEffect(() => {
        Promise.all([getIsAppActive('admin_createanapp')])
            .then(([admin_createanapp]) => {
                const apps = []
                if (admin_createanapp) { apps.push('admin_createanapp') }
                setActiveApps(apps);
            })
    }, [])

    useEffect(() => {
        if (!appMode || !activeApps.find(v => v === 'admin_createanapp')) {
            overridesState.setActiveApp(undefined);
        }
    }, [appMode, activeApps])

    useEffect(() => {
        const default_deployment = overridesState.openAIDeployments.find(d => d.default)
        if (overridesState.activeApp) {
            overridesState.setSelectedDataSource('documents');
            const deployment = overridesState.openAIDeployments.find(d => d.deployment === overridesState.activeApp!.model);
            if (deployment) {
                overridesState.setSelectedDeployment(deployment);
            }
            else {
                overridesState.setSelectedDeployment(default_deployment || overridesState.openAIDeployments[0]);
            }
            const selectedFilter = { category: overridesState.activeApp.categories };
            overridesState.setSelectedDocumentFilters(selectedFilter);
            if (activeAppIsUnpublished) {
                overridesState.setDraftMode();
            }
            else if (!activeAppHasDraft) {
                overridesState.clearDraftMode();
            }
        }
        else {
            overridesState.setSelectedDataSource(defaultSelectedDataSource);
            overridesState.setSelectedDeployment(default_deployment || overridesState.openAIDeployments[0]);
            overridesState.setSelectedDocumentFilters({});
        }
    }, [overridesState.activeApp])

    useEffect(() => {
        setChoices(overridesState.openAIDeployments.toSorted((a, b) => a.order - b.order).map(d => {
            return {
                key: d.deployment,
                onRenderLabel: (props, render) => {
                    return (
                        <div className={styles.radioLabel}>
                            <div className={styles.radioText}>{d.name}</div>
                            <div className={styles.radioSubText}>{d.token_limit} tokens</div>
                        </div>
                    )
                }
            } as IChoiceGroupOption
        }
        ))
    }, [overridesState]);

    const structure_demo = useMemo(() => overridesState.configured_sections.includes('admin_structured_data'), [overridesState.configured_sections])
    useEffect(() => {
        if (structure_demo) {
            Promise.all([TestConnection()]);
        }
    }, [structure_demo])


    const dataSources: IChoiceGroupOption[] = useMemo(() => [
        // { key: 'all', text: 'All (Best Effort)', defaultChecked: true },
        structure_demo && { key: 'aflstruct', text: 'Structured Data Demo (AFL)', disabled: !connectionTest } || undefined,
        { key: 'documents', text: 'Curated Documents' },
        { key: 'none', text: 'No Data Source (unaugmented GPT response)', defaultChecked: true }
    ].filter(x => x !== undefined)
        .map(ds => {
            return {
                ...ds, onRenderLabel: () => (
                    <div className={styles.radioLabel}>
                        <div className={styles.radioText}>{ds.text}</div>
                    </div>
                )
            }
        }), [connectionTest, structure_demo]);

    const activeAppIsUnpublished = overridesState.activeApp && AppEntityKeySet.some(k => overridesState.activeApp![k[1]] === undefined)
    const activeAppHasDraft = overridesState.activeApp && AppEntityKeySet.some(k => !compare(overridesState.activeApp![k[0]], overridesState.activeApp![k[1]]))

    return (
        <article className={styles.article}>
            <section>
                <header className={styles.Banner} onClick={toggleAppDescriptionOpen}>
                    <div>GPT Applications</div>
                    <ReactSVG className={styles.caret} src={appDescriptionOpen ? ArrowUp : ArrowDown} />
                </header>
                <div className={[styles.description, appDescriptionOpen ? 'open' : undefined].filter(x => x).join(' ')}>
                    <p>Select an application to use. This will automatically set a model and data-source (and categories) and provide a dedicated prompt.</p>
                </div>
                {(activeApps.find(v => v === 'admin_createanapp')) &&
                    <section className={styles.panel}>
                        <Switch
                            className={[styles.switch, appMode ? 'checked' : undefined].filter(x => x).join(' ')}
                            label="Use a dedicated App"
                            checked={appMode}
                            onChange={toggleAppMode}
                        />
                        {appMode && (
                            <Stack tokens={{childrenGap: 5}}>
                                <Stack.Item>
                                    <AppSelector
                                        activeApp={overridesState.activeApp}
                                        setActiveApp={overridesState.setActiveApp}
                                        entities={overridesState.appEntities ?? []}
                                        canUseDraftMode={isAdminUser}
                                        isInDraftMode={overridesState.draftMode}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Switch
                                        className={[styles.switch, overridesState.draftMode ? 'checked' : undefined].filter(x => x).join(' ')}
                                        label="Use draft"
                                        checked={overridesState.draftMode}
                                        disabled={!overridesState.activeApp || activeAppIsUnpublished || !activeAppHasDraft}
                                        onChange={overridesState.toggleDraftMode}
                                    />
                                </Stack.Item>
                            </Stack>
                        )}
                    </section>
                }
            </section>
            <section>
                <header className={styles.Banner} onClick={toggleModelDescriptionOpen}>
                    <div>GPT Model</div>
                    <ReactSVG className={styles.caret} src={modelDescriptionOpen ? ArrowUp : ArrowDown} />
                </header>
                <div className={[styles.description, modelDescriptionOpen ? 'open' : undefined].filter(x => x).join(' ')}>
                    <p>Select the GPT Model used to answer when answering the questions.</p>
                    <p>There may be a trade off between performance and tokens available. Some models respond faster but can't process as many tokens. Reduced token sizes affects the number of search results the bot can use to augment the responses, and therefore reduces the reliability of the answer.</p>
                    <p>Smaller token sizes also affects the size(s) of the documents that can be injested as part of a session document query.</p>
                    <p>Select 'No Data Source' to force GPT to respond directly.</p>
                </div>
                <section className={styles.panel}>
                    <ChoiceGroup
                        className={styles.radio}
                        label="GPT Model"
                        selectedKey={overridesState.selectedDeployment?.deployment}
                        options={choices}
                        onChange={onSelectModel}
                        disabled={!!overridesState.activeApp && !!overridesState.activeApp.model}
                    />
                </section>
            </section>
            <section>
                <header className={styles.Banner} onClick={toggleDatasourcelDescriptionOpen}>
                    <div>GPT Data Source</div>
                    <ReactSVG className={styles.caret} src={datasourceDescriptionOpen ? ArrowUp : ArrowDown} />
                </header>
                <div className={[styles.description, datasourceDescriptionOpen ? 'open' : undefined].filter(x => x).join(' ')}>
                    <p>Choose a data-source from which GPT will use to answer the question, or leave it up to the code to decide.</p>
                    <p>Some questions might answer better when targeting a single datasource</p>
                </div>
                <section className={styles.panel}>
                    <ChoiceGroup
                        className={styles.radio}
                        label="GPT Data Source"
                        selectedKey={overridesState.selectedDataSource ?? defaultSelectedDataSource}
                        options={dataSources}
                        disabled={!!overridesState.activeApp}
                        onChange={onSelectDS}
                    />
                </section>
            </section>
            <section>
                <header className={styles.Banner}>
                    <div>Other Settings</div>
                </header>
                <section className={styles.panel}>
                    <Switch
                        className={[styles.switch, overridesState.logPrompts ? 'checked' : undefined].filter(x => x).join(' ')}
                        label="Log prompt and augmentation to Console"
                        checked={overridesState.logPrompts}
                        onChange={onSwitchLogPrompts}
                    />
                </section>
                <section className={styles.panel}>
                    <Switch
                        disabled={overridesState.selectedDataSource !== 'aflstruct'}
                        className={[styles.switch, overridesState.useNamesSearch ? 'checked' : undefined].filter(x => x).join(' ')}
                        label="Presearch names before generating SQL"
                        checked={overridesState.useNamesSearch}
                        onChange={onSwitchNamesSearch}
                    />
                </section>
            </section>
        </article>
    )
}
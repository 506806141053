import * as React from 'react';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId, useBoolean } from '@fluentui/react-hooks';
import styles from "./DeleteDocument.module.scss";
import { ThemeProvider, IIconProps, Modal } from '@fluentui/react';
import darkTheme from "../../fluidThemes/darkTheme";

import { toast } from 'react-toastify';
import { deleteFiles, IMetaData } from '../../api';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};
interface DocumentDeleteProps {
  deleteRowId: string | undefined;
  reloadPage: () => Promise<void>;
  tableData: IMetaData[]|undefined;
  setDocumentData: React.Dispatch<React.SetStateAction<IMetaData[]|undefined>>;
  disabled: boolean;
  setShowSpinner: (show: boolean) => void;
}
export const DeleteDocument = ({ deleteRowId, reloadPage, disabled = false, setShowSpinner, tableData, setDocumentData }: DocumentDeleteProps) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const cancelIcon: IIconProps = { iconName: 'Cancel' };

  const { instance } = useMsal();
  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable, labelId, subTextId],
  );
  const deleteDocument = async () => {
    try {
      setShowSpinner(true);
      const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
      const deletefile = await deleteFiles(deleteRowId ?? "", token);
      if (deletefile) {
        const dataItem = tableData?.filter((document) =>(document.title === deleteRowId))[0];
        if(dataItem!=undefined)
          dataItem.status = "Deleting";         
        setDocumentData(tableData);
        toast.success(`File deleted successfully.`)
      }
    } catch (error) {
      const err: Error | AggregateError = error as any;
      toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false })
    }
    finally {
      toggleHideDialog();
      setShowSpinner(false);
    }
  }



  const openDialog = () => {
    if (deleteRowId)
      toggleHideDialog();
  }
  return (
    <>
      <PrimaryButton disabled={disabled} theme={darkTheme} className={styles.accentbutton} secondaryText="Delete" onClick={openDialog} text="Delete" />
      <ThemeProvider applyTo="body" theme={darkTheme}>

        <Modal
          isOpen={hideDialog}
          onDismiss={toggleHideDialog}
          containerClassName={styles.dialog}
          isModeless={false}
          dragOptions={isDraggable ? dragOptions : undefined}
        >
          <div className={styles.header}>
            <h2 id={"title"}>
            </h2>
            <IconButton
              className={styles.iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={toggleHideDialog}
            />
          </div>
          <div className={styles.dialogBody}>
            <span>Are you sure you want to delete the selected document/documents?</span>
          </div>
          <div className={styles.footer}>
            <DefaultButton theme={darkTheme} className={styles.alternativebutton} onClick={toggleHideDialog} text="No" />
            <div className={styles.separator}></div>
            <PrimaryButton theme={darkTheme} className={styles.accentbutton} onClick={deleteDocument} text="Yes" />
          </div>
        </Modal >
      </ThemeProvider>
    </>
  );
};

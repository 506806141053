import { createTheme } from "@fluentui/react";
const darkTheme = createTheme({
    palette: {
      themePrimary: 'var(--fluid-themePrimary)',
      themeLighterAlt: 'var(--fluid-themeLighterAlt)',
      themeLighter: 'var(--fluid-themeLighter)',
      themeLight: 'var(--fluid-themeLight)',
      themeTertiary: 'var(--fluid-themeTertiary)',
      themeSecondary: 'var(--fluid-themeSecondary)',
      themeDarkAlt: 'var(--fluid-themeDarkAlt)',
      themeDark: 'var(--fluid-themeDark)',
      themeDarker: 'var(--fluid-themeDarker)',
      neutralLighterAlt: 'var(--fluid-neutralLighterAlt)',
      neutralLighter: 'var(--fluid-neutralLighter)',
      neutralLight: 'var(--fluid-neutralLight)', 
      neutralQuaternaryAlt: 'var(--fluid-neutralQuaternaryAlt)',
      neutralQuaternary: 'var(--fluid-neutralQuaternary)',
      neutralTertiaryAlt: 'var(--fluid-neutralTertiaryAlt)',
      neutralTertiary: 'var(--fluid-neutralTertiary)',
      neutralSecondary: 'var(--fluid-neutralSecondary)',
      neutralPrimaryAlt: 'var(--fluid-neutralPrimaryAlt)',
      neutralPrimary: 'var(--fluid-neutralPrimary)',
      neutralDark: 'var(--fluid-neutralDark)',
      black: 'var(--fluid-black)', 
      white: 'var(--fluid-white)',
    }});

export default darkTheme;
import React, { useState, createContext, useEffect } from 'react';
import { DocumentFilter, getIsAppActive, OpenAIDeployment } from "../api/api"
import { AppEntity, GPT_Overrides } from '../api';
type OverrideFilter<T> = (_: T) => void;
type OverrideVoidFilter = () => void;
interface Overrides {
    openAIDeployments: OpenAIDeployment[],
    setOpenAIDeployments: OverrideFilter<OpenAIDeployment[]>;
    selectedDeployment?: OpenAIDeployment,
    setSelectedDeployment: OverrideFilter<OpenAIDeployment>;
    selectedDataSource?: string;
    setSelectedDataSource: OverrideFilter<string>;
    documentFilters?: DocumentFilter,
    setDocumentFilters: OverrideFilter<DocumentFilter>;
    selectedDocumentFilters?: DocumentFilter,
    setSelectedDocumentFilters: OverrideFilter<DocumentFilter>;
    logPrompts: boolean;
    setLogPrompts: OverrideFilter<boolean>;
    useNamesSearch: boolean | undefined;
    setUseNamesSearch: OverrideFilter<boolean>;
    appEntities: AppEntity[] | undefined;
    setAppEntities: OverrideFilter<AppEntity[] | undefined>;
    activeApp: AppEntity | undefined;
    setActiveApp: OverrideFilter<AppEntity | undefined>;
    configured_sections: string[];
    toggleDraftMode: OverrideVoidFilter;
    setDraftMode: OverrideVoidFilter;
    clearDraftMode: OverrideVoidFilter;
    draftMode: boolean;
    user_roles: string[];
}


const no_op = <T,>(_: T) => { }
const no_op_void = () => {}
export const OverridesContext = createContext({
    openAIDeployments: [],
    setOpenAIDeployments: no_op,
    selectedDeployment: undefined,
    setSelectedDeployment: no_op,
    selectedDataSource: undefined,
    setSelectedDataSource: no_op,
    documentFilters: undefined,
    setDocumentFilters: no_op,
    selectedDocumentFilters: undefined,
    setSelectedDocumentFilters: no_op,
    logPrompts: false,
    setLogPrompts: no_op,
    useNamesSearch: undefined,
    setUseNamesSearch: no_op,
    appEntities: undefined,
    setAppEntities: no_op,
    activeApp: undefined,
    setActiveApp: no_op,
    configured_sections: [],
    toggleDraftMode: no_op_void,
    setDraftMode: no_op_void,
    clearDraftMode: no_op_void,
    draftMode: false,
    user_roles: []
} as Overrides)

export const OverridesContextProvider = (props: React.PropsWithChildren) => {
    const setOpenAIDeployments = (deployments: OpenAIDeployment[]) => {
        setState(prev => { return { ...prev, openAIDeployments: deployments } });
    }
    const setSelectedDeployment = (deployment: OpenAIDeployment) => {
        setState(prev => { return { ...prev, selectedDeployment: deployment } });
    }
    const setSelectedDataSource = (datasource: string) => {
        setState(prev => { return { ...prev, selectedDataSource: datasource } });
    }
    const setDocumentFilters = (dfs: DocumentFilter) => {
        setState(prev => { return { ...prev, documentFilters: dfs } });
    }
    const setSelectedDocumentFilters = (dfs: DocumentFilter) => {
        setState(prev => { return { ...prev, selectedDocumentFilters: dfs } });
    }
    const setLogPrompts = (val: boolean) => {
        setState(prev => { return { ...prev, logPrompts: val } })
    }
    const setUseNamesSearch = (val: boolean) => {
        setState(prev => { return { ...prev, useNamesSearch: val } })
    }
    const setActiveApp = (val: AppEntity | undefined) => {
        setState(prev => { return { ...prev, activeApp: val } })
    }
    const setAppEntities = (entities: AppEntity[] | undefined) => {
        setState(prev => { return { ...prev, appEntities: entities } });
    }
    const toggleDraftMode = () => {
        setState(prev => { return { ...prev, draftMode: !prev.draftMode } });
    }
    const setDraftMode = () => {
        setState(prev => { return { ...prev, draftMode: true } });
    }
    const clearDraftMode = () => {
        setState(prev => { return { ...prev, draftMode: false } });
    }
    const initialState: Overrides = {
        openAIDeployments: [],
        setOpenAIDeployments: setOpenAIDeployments,
        selectedDeployment: undefined,
        setSelectedDeployment: setSelectedDeployment,
        selectedDataSource: undefined,
        setSelectedDataSource: setSelectedDataSource,
        documentFilters: undefined,
        setDocumentFilters: setDocumentFilters,
        selectedDocumentFilters: undefined,
        setSelectedDocumentFilters: setSelectedDocumentFilters,
        logPrompts: false,
        setLogPrompts: setLogPrompts,
        useNamesSearch: undefined,
        setUseNamesSearch: setUseNamesSearch,
        appEntities: undefined,
        setAppEntities: setAppEntities,
        activeApp: undefined,
        setActiveApp: setActiveApp,
        configured_sections: [],
        toggleDraftMode: toggleDraftMode,
        setDraftMode: setDraftMode,
        clearDraftMode: clearDraftMode,
        draftMode: false,
        user_roles: [],
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        console.log(state);
    }, [state]);

    useEffect(() => {
        Promise.all([
            getIsAppActive('admin_documents'), 
            getIsAppActive('admin_createanapp'), 
            getIsAppActive('admin_structured_data')])
        .then(([admin_documents, admin_createanapp, admin_structured_data]) => {
            const apps = [
                admin_documents && 'admin_documents' || undefined,
                admin_createanapp && 'admin_createanapp' || undefined,
                admin_structured_data && 'admin_structured_data' || undefined,
            ].filter(x => x !== undefined);
            setState(prev => { return {...prev, configured_sections: apps}})
        });
    }, [])

    return (
        <OverridesContext.Provider value={state}>
            {props.children}
        </OverridesContext.Provider>
    )
}
import { useEffect, useState } from "react";
import {
    Stack, PrimaryButton, TextField, Dropdown, IDropdownOption,
} from "@fluentui/react";
import { FilePond, registerPlugin } from "react-filepond";
import { toast } from "react-toastify"
import "filepond/dist/filepond.min.css";
import styles from "./DocumentUploader.module.scss";
import infoTheme from "../../fluidThemes/infoTheme";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePondFile, FilePondInitialFile } from "filepond";
import { editDocument, getCategoriesFromAPI, processDocuments, getDocumentTypesFromAPI, IMetaData } from "../../api";
import infooutline_icon from "../../assets/ic_baseline-info.svg";
import { useBoolean } from "@fluentui/react-hooks";
import { ReactSVG } from "react-svg";
import { ThemeProvider } from '@fluentui/react';
import darkTheme from "../../fluidThemes/darkTheme";
import { Updates, Update, getMediaTypesFromAPI, checkNameExists } from "../../api/api";
import { authUserRole } from "../../utils/AuthUserRole";
import { TagpickerComponent } from "../TagpickerComponent/TagpickerComponent";
import { getFileExtension } from "../../utils/FilenameUtils";
import { ExistingFileConfirmation } from "./../ExistingFileConfirmation/ExistingFileConfirmation";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
interface DocumentUploaderProps {
    Metadata: IMetaData | undefined;
    editRowData: (rowData: IMetaData | undefined) => void;
    setShowSpinner: (show: boolean) => void;
    docTypeName: string;
    ctOptions: string[];
    allowedExtensions: string[];
    tableData: IMetaData[] | undefined;
    setDocumentData: React.Dispatch<React.SetStateAction<IMetaData[]|undefined>>;
}

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DocumentUploader = ({ Metadata, editRowData, setShowSpinner, docTypeName, ctOptions, allowedExtensions, tableData, setDocumentData }: DocumentUploaderProps) => {

    const [files, setFiles] = useState<FilePondFile[]>([]);
    const [isProcessing, { toggle: toggleIsProcessing }] = useBoolean(false);
    const [isFilePondProcessing, { setTrue: setProcessing, setFalse: setDone }] = useBoolean(false);

    const { instance } = useMsal();
    const [txtName, setTxtName] = useState<string>();
    const [txtDescription, setTxtDescription] = useState<string>();
    const [txtType, setTxtType] = useState<string>();
    const [txtExtension, setTxtExtension] = useState<string>("");
    const [txtCategory, setTxtCategory] = useState<string>();
    const { isAdminUser, isNormalUser, isMediaUser } = authUserRole();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isInvalid, setIsInvalid] = useState<boolean>(true);
    const [categoryOptions, setCategoryOptions] = useState<IDropdownOption[]>([]);
    const [continueUpload, setContinueUpload] = useState<boolean>();
    const [uploadWarning, setUploadWarning] = useState<string>();
    const [showUploadWarning, setShowUploadWarning] = useState<boolean>();
    useEffect(() => {
        window.onbeforeunload = () => {
            instance.acquireTokenSilent(loginRequest);
        }
    })

    const isAggregateError = (error: Error | AggregateError): error is AggregateError => (error as AggregateError).errors !== undefined;

    const handleFormSubmit = async () => {

        try {
            setShowSpinner(true);
            const authResult = await instance.acquireTokenSilent(loginRequest)
            const token = authResult.accessToken
            const userName = (authResult.idTokenClaims as any)?.name ?? "";
            if (Metadata == undefined || Metadata.id.length <= 0) {
                const jfileId = JSON.parse(files[0].serverId);
                const document = {
                    id: jfileId['temp_id'],
                    title: `${txtName ?? jfileId}.${txtExtension ?? 'file'}`,
                    description: txtDescription ?? "",
                    category: txtCategory ?? "",
                    type: txtType,
                    date_uploaded: new Date().toISOString(),
                    uploaded_by: userName,
                    tagarray: selectedTags,
                    status: 'uploaded'
                } as IMetaData
                const uploadedfiles = await processDocuments(document, token);

                if (uploadedfiles) {                    
                    let newData = tableData?.map((document) => document);                
                    newData?.push(document);
                    setDocumentData(newData);
                    tableData = newData;
                    toast.success(`File ${txtName} uploaded successfully.`)
                }
            } else {
                const record: Update = { title: txtName ?? "", description: txtDescription ?? "", category: txtCategory ?? "", type: txtType ?? "", uploaded_by: "", tags: selectedTags, mode: "replace" }
                const records: Updates = { updates: [] };
                records.updates.push(record);
                const editfile = await editDocument(records, token ?? "");

                if (editfile) {
                    const dataItem = tableData?.filter((document) =>(document.title === record.title))[0];
                    if(dataItem!=undefined){
                        dataItem.description = record.description??""; 
                        dataItem.tagarray = record.tags??[]; 
                        dataItem.category = record.category??"";      
                    }   
                    setDocumentData(tableData);
                    toast.success(`File ${txtName} edited successfully.`)
                }
            }
            await resetUpload();
        } catch (error) {
            const err: Error | AggregateError = error as any;
            if (isAggregateError(err)) {
                err.errors.forEach(e => {
                    toast.error(e.message, { theme: "colored", hideProgressBar: true, autoClose: false })
                });
            }
            else {
                toast.error(err.message, { theme: "colored", hideProgressBar: true, autoClose: false })
            }
        }
        finally {
            toggleIsProcessing();
            setShowSpinner(false);
        }
    };
    const onBeforeAddFile = async (file: FilePondFile) => {

        const found = !!~allowedExtensions.indexOf(file.fileExtension)
        if (!found) {
            toast.error("Uploading selected file type is not allowed.", { theme: "colored", hideProgressBar: true, autoClose: false })
        }
        return found;
    }

    useEffect(() => {
        setShowSpinner(true);
        setShowSpinner(false);
    }, [isAdminUser, isMediaUser]);
    useEffect(() => {
        const items: IDropdownOption[] = ctOptions.map(x => ({ key: x, text: x }));
        setCategoryOptions(items);
    }, [ctOptions]);

    useEffect(() => {
        setTxtName(Metadata?.title);
        setTxtExtension(getFileExtension(Metadata?.title ?? ""));
        setTxtDescription(Metadata?.description);
        setTxtCategory(Metadata?.category);
        setTxtType(Metadata?.type);
        setSelectedTags(Metadata?.tagarray ?? []);
    }, [Metadata]);
    const validateFileName = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
        setShowSpinner(true);
        if (txtName != undefined && txtName.length > 0 && txtExtension != undefined && txtExtension.length > 0) {
            const filename = (txtName ?? "") + "." + (txtExtension ?? "");
            const ct = await checkNameExists(filename, token);

            const exists = Object.values(ct)[0];
            if (ct && exists) {
                setShowUploadWarning(true);
                setShowSpinner(false);
                const status = ct.status;
                switch (status) {
                    case "deleted":
                        setUploadWarning(`File '${filename}' already exists and soft deleted. Are you sure you want to continue Saving to overwrite?`);
                        break;
                    default:
                        setUploadWarning(`File '${filename}' already exists. Are you sure you want to continue Saving to overwrite?`);
                        break;
                }
                setIsInvalid(true);
            }
            else {
                setContinueUpload(true);
                setUploadWarning("");
                setShowSpinner(false);
            }
        }
        else {
            setContinueUpload(true);
            setUploadWarning("");
            setShowSpinner(false);
        }
    }
    useEffect(() => {
        if (txtName != undefined && txtName.length > 0) {
            validateFileName();
        }
    }, [txtName])

    const resetUpload = async () => {
        setTxtName("");
        setTxtType("");
        setTxtExtension("");
        setTxtDescription("");
        setTxtCategory("");
        setSelectedTags([]);
        setFiles([]);
    }
    const confirm = async (response: boolean) => {
        setContinueUpload(response);
        setShowUploadWarning(false);
        if (!response) { setFiles([]) };
    }

    return (
        <>
            <article className={styles.uploaderArticle}>
                <section className={styles.Uploader}>
                    <Stack className={styles.uploaderStack}>
                        <Stack.Item>
                            <div className={styles.uploaderHeader}>
                                <span >{(Metadata == undefined || Metadata.id.length <= 0) ? `Upload ${docTypeName}` : `Edit ${docTypeName}`}</span>
                            </div>
                            <ThemeProvider applyTo="body" theme={darkTheme}>
                                <form onSubmit={handleFormSubmit} noValidate>
                                    {(Metadata == undefined || Metadata.id.length <= 0) &&
                                        <>
                                            <FilePond
                                                className={styles.FilePond}
                                                files={files as unknown as FilePondInitialFile[]}
                                                onupdatefiles={setFiles}
                                                allowMultiple={false}
                                                acceptedFileTypes={allowedExtensions}
                                                credits={false}
                                                server="/adminupload"
                                                beforeAddFile={onBeforeAddFile}
                                                name="file"
                                                labelIdle='Drag & drop your files or <span class="filepond--label-action">Browse</span>'
                                                onprocessfile={(error, file) => {
                                                    if (error) {
                                                        toast.error(`File is either currupted or file type is not allowed`, { theme: "colored", hideProgressBar: true, autoClose: false })
                                                    }
                                                    else {
                                                        console.log('File processed', file);
                                                        setTxtName(file.filenameWithoutExtension);
                                                        setTxtType(file.fileExtension);
                                                        setTxtExtension(file.fileExtension);
                                                    }
                                                }}
                                                onaddfilestart={(file) => {
                                                    console.log('Start adding', file);
                                                    setProcessing();
                                                }}
                                                onprocessfiles={setDone}
                                                onremovefile={(file) => {
                                                    resetUpload();
                                                }}
                                            />
                                            <div className={styles.iconText}>
                                                <ReactSVG src={infooutline_icon} className={styles.iconRegularInfo} /> When you upload a file, it is initially stored in a temporary location on the server. Once you click “Save”, the file is then moved from this temporary location to a permanent storage area.
                                            </div>
                                        </>
                                    }
                                    <div className={styles.iconText}>
                                        <ReactSVG src={infooutline_icon} className={styles.iconRegularInfo} />Supported Files: {allowedExtensions.join(", ").toUpperCase()}
                                    </div>
                                    <span>Name</span>
                                    <TextField
                                        className={styles.panelControl}
                                        placeholder={`Enter ${docTypeName} Name`}
                                        value={txtName}
                                        suffix={'.' + txtExtension}
                                        resizable={false}
                                        onChange={((t, value) => { setTxtName(value) })}
                                    />
                                    <span>Description</span>
                                    <TextField
                                        className={styles.panelControl}
                                        placeholder={"Enter Description"}
                                        value={txtDescription}
                                        resizable={false}
                                        multiline={true}
                                        onChange={((t, value) => { setTxtDescription(value) })}
                                    />
                                    <span>Category</span>
                                    <Dropdown
                                        className={styles.panelControl}
                                        placeholder={"Select Category"}
                                        selectedKey={txtCategory}
                                        options={categoryOptions}
                                        onChange={((t, value) => { setTxtCategory(value?.text) })}
                                    />
                                    <span>Tags</span>
                                    <TagpickerComponent
                                        setSelectedTags={setSelectedTags}
                                        selectedTags={selectedTags}
                                    />
                                    <PrimaryButton onClick={handleFormSubmit} disabled={(!continueUpload || isFilePondProcessing)} className={styles.controlButton} theme={infoTheme}>
                                    {(Metadata == undefined || Metadata.id.length <= 0)? "Upload":"Save changes"}
                                    </PrimaryButton>
                                </form>
                            </ThemeProvider>
                        </Stack.Item>
                    </Stack>
                </section>
            </article>
            <ExistingFileConfirmation
                showUploadWarning={showUploadWarning ?? false}
                setShowUploadWarning={setShowUploadWarning}
                message={uploadWarning ?? ""}
                confirm={confirm} />
        </>
    );
};

export default DocumentUploader;

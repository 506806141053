import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId, useBoolean } from '@fluentui/react-hooks';
import styles from "./ExistingFileConfirmation.module.scss";
import { ThemeProvider, IIconProps, Modal } from '@fluentui/react';
import darkTheme from "../../fluidThemes/darkTheme";
import { toast } from 'react-toastify';
import { deleteFiles } from '../../api';
import { useEffect, useState } from 'react';
const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};
interface ExistingFileConfirmationProps {
  showUploadWarning:boolean;
  setShowUploadWarning:(showUploadWarning: boolean) => void;
  message:string;
  confirm: (response: boolean) => void;
}
export const ExistingFileConfirmation = ({ showUploadWarning, setShowUploadWarning, message, confirm }: ExistingFileConfirmationProps) => {
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId('uploadconfirmationdialogLabel');
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  
  return (
    <>
      <ThemeProvider applyTo="body" theme={darkTheme}>
        <Modal
          isOpen={showUploadWarning}
          onDismiss={()=>confirm(false)}
          containerClassName={styles.dialog}
          isModeless={false}
          dragOptions={isDraggable ? dragOptions : undefined}
        >
          <div className={styles.header}>
            <h2 id={labelId}>
            </h2>
            <IconButton
              className={styles.iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={()=>confirm(false)}
            />
          </div>
          <div className={styles.dialogBody}>
            <span>{message??""}</span>
          </div>
          <div className={styles.footer}>
            <DefaultButton onClick={()=>confirm(false)}  text="No" />
            <div className={styles.separator}></div>
            <PrimaryButton onClick={()=>confirm(true)} text="Yes" />
          </div>
        </Modal >
      </ThemeProvider>
    </>
  );
};

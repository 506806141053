import { EventPayload, AuthenticationResult } from "@azure/msal-browser";
import { TokenClaims } from "@azure/msal-common";
/**
 * Identifies whether the payload is specifically an AuthenticationResult
 * @param {EventPayload} payload - Payload of type EventPayload
 * @returns {boolean} - typecasts payload to AuthenticationResult
 */
export const isAccountInfo = (payload: EventPayload): payload is AuthenticationResult => !!payload && "account" in payload && !!payload.account;
/**
 * Compare item to all items in array; returns true if item matches at least one item in the array
 * @param {T} item
 * @param {T[]} arr
 * @returns {boolean}
 */
export const isIn = <T>(item: T, ...arr: T[]): boolean => arr.some(v => v === item);

/**
 * Compare the token issuing policy with a specific policy name
 * @param {object} idTokenClaims - Object containing the claims from the parsed token
 * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
 * @returns {boolean}
 */
export function compareIssuingPolicy(idTokenClaims: object, policyToCompare: string): idTokenClaims is TokenClaims & { [k: string]: unknown } {
    const itc = idTokenClaims as { [k: string]: string };
    let tfpMatches = itc.hasOwnProperty("tfp") && itc["tfp"].toLowerCase() === policyToCompare.toLowerCase(),
        acrMatches = itc.hasOwnProperty("acr") && itc["acr"].toLowerCase() === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
}

import { useRef, useState, useEffect, useReducer, useContext, Fragment, useMemo, useId } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { DefaultButton, Dialog, DialogFooter, DialogType, ResponsiveMode, Stack } from "@fluentui/react";
import { DismissRegular, ErrorCircleRegular } from "@fluentui/react-icons";
import { UserIcon } from "../../components/UserIcon";
import { ReactSVG } from "react-svg";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";

import styles from "./Chat.module.scss";
import BizData_short from "../../assets/BizData short Logo.svg"
import Robot from "../../assets/Robot without background.svg"
import regen from "../../assets/regenerate.svg";
import add from "../../assets/add.svg"
import { ConfigurationPanel } from "../../components/ConfigurationPanel";

import accentTheme from "../../fluidThemes/accentTheme";

import { setNewChat, clearNewChat, event } from "../../interfaces/newchat"
import { setTogglePanel, clearTogglePanel } from "../../interfaces/recentpanel"
import { setTogglePanel as setToggleConfigPanel, clearTogglePanel as clearToggleConfigPanel, event as configEvent } from "../../interfaces/uploadpanel"
import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    WelcomeText,
    getWelcomeText,
    SavedChatResponse,
    SavedChat,
    Dictionarise,
    getChatSessions,
    getChatSessionsStream,
    getChatSession,
    deleteChatSession,
    getOpenAIDeployments,
    getDocumentFilters,
    GPT_Overrides,
    AnswerMetadata,
    Prompts,
    Section,
    AppEntity,
    getAppsStream
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { toast } from "react-toastify";
import { RecentActivityPanel } from "../../components/RecentActivityPanel";
import { loginRequest } from "../../authConfig";

import { SideBar } from "../../components/SideBar/SideBar";
import { OverridesContext, OverridesContextProvider } from "../../interfaces/overridesContextManagement";
import { useStateWithCallback } from "../../utils/customHooks";
import { useMsal } from "@azure/msal-react";
import { SideBarContents } from "../../components/SideBarContents/SideBarContents";

type DeletedChat = {
    id: string;
    operation: "deleted"
}

const findReverseIndex = <T,>(arr: T[], pred: ((x: T) => boolean)) => arr.slice().reverse().findIndex(pred) + 1;
const findLastIndex = <T,>(arr: T[], pred: ((x: T) => boolean)) => arr.length - findReverseIndex(arr, pred);
const getUntil = <T,>(arr: T[], pred: ((x: T) => boolean)) => arr.slice().splice(0, findLastIndex(arr, pred))

const isSavedChat = (x: SavedChat | SavedChatResponse | DeletedChat): x is SavedChat => Object.keys(x).every(k => !!(x as SavedChat)[k].title)
const isSavedChatReponse = (x: SavedChat | SavedChatResponse | DeletedChat): x is SavedChatResponse => !!(x as SavedChatResponse).chat_id
const isDeletedChat = (x: SavedChat | SavedChatResponse | DeletedChat): x is DeletedChat => (x as DeletedChat).operation === "deleted"

const Chat = () => {
    const [isConfigPanelOpen, { toggle: toggleConfigPanel, setFalse: closeConfigPanel, setTrue: openConfigPanel }] = useBoolean(false);
    const [configPivot, setConfigPivot] = useState<Section>("uploader")
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useStateWithCallback<boolean>(false);
    const [isRetrieving, setIsRetrieving] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useStateWithCallback<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>();
    const [activeSession, setActiveSession] = useState<SavedChatResponse | undefined>(undefined);
    const [historyState, historyDispatcher] = useReducer((state: SavedChat, activeSession: SavedChatResponse | SavedChat | DeletedChat) => {
        if (isSavedChat(activeSession)) {
            return activeSession;
        }
        if (isDeletedChat(activeSession)) {
            delete state[activeSession.id]
        }
        else {
            state[activeSession.chat_id] = {
                title: activeSession.title,
                created_timestamp: activeSession.created_timestamp,
                modified_timestamp: activeSession.modified_timestamp
            };
        }
        return state;
    }, {})
    const [historyStat, setHistoryState] = useState<SavedChat>({});
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const [answers, setAnswers] = useStateWithCallback<ChatMessage[]>([]);
    const abortFuncs = useRef([] as AbortController[]);
    const [welcomeText, setWelcomeText] = useState<WelcomeText | undefined>(undefined)
    const [fileIds, setFileIds] = useState<string[]>([])
    const [tokensRemaining, setTokensRemaining] = useState<number>(0);
    const [questionText, setQuestionText] = useState<string>();
    const overridesState = useContext(OverridesContext);
    const [isChatHistoryVisible, { toggle: toggleChatHistoryVisable, setFalse: closeChatHistory }] = useBoolean(false);
    const { instance } = useMsal();
    const [hiddenDialog, { toggle: toggleHideDialog, setTrue: hideDialog, setFalse: showDialog }] = useBoolean(true)


    const setIsConfigPanelOpen = (state: boolean) => state ? openConfigPanel() : closeConfigPanel();

    const [sessionText, setSessionText] = useState<string>()
    const sessionToDelete = useRef<string>()
    const onConfirm = async () => {
        hideDialog();
        sessionToDelete.current && await onDeleteSessionClickHandler(sessionToDelete.current)()
    }

    const onShowDialog = (session: string) => () => {
        sessionToDelete.current = session;
        setSessionText(sessionToDelete.current !== undefined ? `Do you want to delete the session "${historyStat[sessionToDelete.current].title}"?` : "");
        showDialog();
    }

    const dialogContentProps = useMemo(() => ({
        type: DialogType.normal,
        title: "Are you sure?",
        subText: sessionText
    }), [sessionText])

    const getWelcomeTextFromApi = async () => {
        const wt = await getWelcomeText();
        if (wt) {
            setWelcomeText(wt);
        }
    }

    const getOpenAIDeploymentsFromApi = async () => {
        const deployments = await getOpenAIDeployments();
        if (deployments) {
            overridesState.setOpenAIDeployments(deployments);
            const defaultDeployment = deployments.find(d => d.default);
            overridesState.setSelectedDeployment(overridesState.selectedDeployment ?? defaultDeployment ?? deployments[0])
        }
    }

    const getOverrides = () => {
        let any = false;
        const overrides: GPT_Overrides = {};
        if (overridesState.selectedDeployment?.deployment) {
            overrides.deployment = overridesState.selectedDeployment.deployment;
            any = true
        }
        if (overridesState.selectedDataSource) {
            overrides.datasource = overridesState.selectedDataSource;
            any = true
        }
        if (overridesState.selectedDocumentFilters) {
            overrides.documentFilters = overridesState.selectedDocumentFilters;
            any = true
        }
        if (overridesState.useNamesSearch) {
            overrides.useNameSearch = overridesState.useNamesSearch;
            any = true
        }
        if (overridesState.activeApp) {
            overrides.activeAppId = overridesState.activeApp.RowKey;
            any = true
        }
        if (overridesState.draftMode) {
            overrides.draftMode = overridesState.draftMode;
            any = true
        }
        if (any) {
            return overrides;
        }
        return undefined;
    }

    const newChatDisabled = useMemo(() => answers.length === 0 && !activeSession?.chat_id || isLoading, [answers, activeSession, isLoading]);

    useEffect(() => {
        event.process(answers.length === 0 && !activeSession?.chat_id || isLoading);
    }, [answers, activeSession, isLoading]);

    const makeApiRequest = async (question: string) => {
        setIsLoading(true,
            () => setShowLoadingMessage(true, _run));

        async function _run() {
            lastQuestionRef.current = question;
            const token = (await instance.acquireTokenSilent(loginRequest)).accessToken

            const abortController = new AbortController();
            abortFuncs.current.unshift(abortController);

            const userMessage: ChatMessage = {
                role: "user",
                content: question
            };

            const request: ConversationRequest = {
                messages: [...answers, userMessage].map(a => {
                    const { answer_metadata, end_turn, ...msg } = a;
                    return msg;
                }),
                ids: fileIds,
                chat_id: activeSession?.chat_id,
                overrides: getOverrides()
            };

            let result = {} as ChatResponse;
            try {
                const response = await conversationApi(request, token, abortController.signal);
                if (response?.body) {

                    const reader = response.body.getReader();
                    let runningText = "";
                    let prompts: Prompts | undefined;
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            if (prompts && overridesState.logPrompts) {
                                prompts?.queryPrompt && console.log(prompts.queryPrompt);
                                prompts?.querystructure && console.log(prompts.querystructure);
                                prompts?.answerPrompt && console.log(prompts.answerPrompt);
                            }
                            break;
                        }

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");
                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                prompts = result.prompts;
                                setShowLoadingMessage(false);
                                setAnswers([...answers, userMessage, ...result.choices[0].messages]);
                                runningText = "";
                            }
                            catch { }
                        });
                    }
                    setAnswers([...answers, userMessage, ...result.choices[0].messages]);
                    if (result.savedChat) {
                        setActiveSession(result.savedChat);
                        const newEntry = {} as SavedChat;
                        newEntry[result.savedChat.chat_id] = {
                            title: result.savedChat.title,
                            created_timestamp: result.savedChat.created_timestamp,
                            modified_timestamp: result.savedChat.modified_timestamp
                        }
                        setHistoryState({ ...historyStat, ...newEntry })
                    }
                }

            } catch (e) {
                if (!abortController.signal.aborted) {
                    console.error(e);
                    let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                    if (result.error?.message) {
                        errorMessage = result.error.message;
                    }
                    else if (typeof result.error === "string") {
                        errorMessage = result.error;
                    }
                    setAnswers([...answers, userMessage, {
                        role: "error",
                        content: errorMessage
                    }]);
                } else {
                    setAnswers([...answers, userMessage]);
                }
            } finally {
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            }

            return abortController.abort();
        };
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        setActiveCitation(undefined);
        setAnswers([]);
        setActiveSession(undefined);
    };

    const toDict = <T, K extends keyof T & string>(arr: T[], id: K): Dictionarise<T, K> => {
        let dict: Dictionarise<T, K> = {};
        arr.forEach(val => {
            const x = val[id]
            let { [id]: _, ...rest } = val;
            dict[(x as string)] = rest as unknown as { [Property in keyof T as Exclude<Property, K>]: T[Property] }
        })
        return dict;
    }

    const fetchChatSessions = async () => {
        setIsRetrieving(true);
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken

        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        let savedChatObj: Dictionarise<SavedChatResponse, 'chat_id'> = {}
        try {
            const response = await getChatSessionsStream(token, abortController.signal);
            if (response?.body) {
                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            const data: { sessions: SavedChatResponse[] } = JSON.parse(runningText);
                            savedChatObj = toDict(data.sessions, "chat_id");
                            setHistoryState(savedChatObj);
                            runningText = "";
                        }
                        catch { }
                    });
                }
                setHistoryState(savedChatObj);
            }
        } catch (error) {
            console.error('Error fetching chat sessions: ', error);
        }
        finally {
            setIsRetrieving(false);
        }

        return abortController.abort();
    };
    const fetchApps = async () => {
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
        const utf8Decoder = new TextDecoder("utf-8")
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try {
            const response = await getAppsStream(token, abortController.signal)
            if (response?.body) {
                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    var text = utf8Decoder.decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            const data: { sessions: AppEntity[] } = JSON.parse(runningText);
                            overridesState.setAppEntities(data.sessions);
                            runningText = "";
                        }
                        catch { }
                    });
                }
            }
        }
        catch (error) {
            console.error('Error fetching apps: ', error);
        }
    }

    useEffect(() => {
        // Fetch chat sessions when the component mounts
        fetchChatSessions();
        fetchApps();
        getWelcomeTextFromApi();
        getOpenAIDeploymentsFromApi();
        getDocumentFilters(['category', 'tags', 'type'])
            .then(f => overridesState.setDocumentFilters(f))
            .catch(console.log);
        setNewChat(clearChat);
        setTogglePanel(toggleChatHistoryVisable);
        setToggleConfigPanel(toggleConfigPanel);
        return () => {
            clearNewChat();
            clearTogglePanel();
            clearToggleConfigPanel();
        };
    }, []);

    useEffect(() => {
        configEvent.process(isConfigPanelOpen);
    }, [isConfigPanelOpen])


    const regenerateAns = async () => {
        setAnswers(prev => [...getUntil(prev, (x: ChatMessage) => x.role === "user")],
            prev => setIsLoading(true,
                () => setShowLoadingMessage(true, () => _run(prev))));

        async function _run(prev: ChatMessage[]) {
            const abortController = new AbortController();
            abortFuncs.current.unshift(abortController);
            const token = (await instance.acquireTokenSilent(loginRequest)).accessToken

            const userMessage: ChatMessage = {
                role: "user",
                content: lastQuestionRef.current
            };

            const request: ConversationRequest = {
                messages: [...prev, userMessage].map(a => {
                    const { answer_metadata, end_turn, ...msg } = a;
                    return msg;
                }),
                ids: fileIds,
                chat_id: activeSession?.chat_id,
                overrides: getOverrides()
            };

            let result = {} as ChatResponse;
            try {
                const response = await conversationApi(request, token, abortController.signal);
                if (response?.body) {

                    const reader = response.body.getReader();
                    let runningText = "";
                    let prompts: Prompts | undefined;
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            if (prompts && overridesState.logPrompts) {
                                prompts?.queryPrompt && console.log(prompts.queryPrompt);
                                prompts?.querystructure && console.log(prompts.querystructure);
                                prompts?.answerPrompt && console.log(prompts.answerPrompt);
                            }
                            break;
                        }

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");
                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                prompts = result.prompts;
                                setShowLoadingMessage(false);
                                setAnswers([...prev, userMessage, ...result.choices[0].messages]);
                                runningText = "";
                            }
                            catch { }
                        });
                    }
                    setAnswers([...getUntil(answers, (x: ChatMessage) => x.role === "user"), userMessage, ...result.choices[0].messages]);
                    if (result.savedChat) {
                        setActiveSession(result.savedChat);
                        historyDispatcher(result.savedChat);
                    }
                }

            } catch (e) {
                if (!abortController.signal.aborted) {
                    console.error(result);
                    let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                    if (result.error?.message) {
                        errorMessage = result.error.message;
                    }
                    else if (typeof result.error === "string") {
                        errorMessage = result.error;
                    }
                    setAnswers([...getUntil(answers, (x: ChatMessage) => x.role === "user"), userMessage, {
                        role: "error",
                        content: errorMessage
                    }]);
                } else {
                    setAnswers([...getUntil(answers, (x: ChatMessage) => x.role === "user"), userMessage,]);
                }
            } finally {
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            }

            return abortController.abort();
        };
    };



    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
        setActiveCitation(undefined);
    }

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [showLoadingMessage, answers]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", citation.url ?? "", ""]);
        setIsCitationPanelOpen(true);
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const onChatHistorySessionClickHandler = (session: string) => async () => {
        setIsRetrieving(true);
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken
        try {
            const sessionData = await getChatSession(session, token);
            console.log('sessionData:', sessionData); // logs the fetched data
            setActiveSession(sessionData)
            const storedanswers: ChatMessage[] | null = JSON.parse(sessionData.data ?? "null")?.filter((a: ChatMessage) => a.content);
            const lastuser = storedanswers?.filter(a => a.role === "user")?.slice(-1)[0]?.content;
            lastQuestionRef.current = lastuser ?? "";
            setAnswers(storedanswers ?? []);
        }
        catch {
            clearChat();
        }
        finally {
            closeChatHistory();
            setIsRetrieving(false);
        }
    }

    const onDeleteSessionClickHandler = (session: string) => async () => {
        if (activeSession?.chat_id === session) {
            clearChat();
        }
        const token = (await instance.acquireTokenSilent(loginRequest)).accessToken

        setHistoryState(oldState => {
            delete oldState[session];
            return oldState;
        });
        await deleteChatSession(session, token);
    }

    const setSessionId = (sessionId: string) => {
        const session = activeSession || ({} as SavedChatResponse)
        session.chat_id = sessionId;
        setActiveSession(session);
    }

    const newchatId = useId();
    return /*authState?.isAuthenticated ?*/ (
        <>
            <div className={styles.container} role="main">
                <SideBar className={styles.LHS}>
                    <>
                        <SideBarContents
                            historyState={historyStat}
                            isRetrieving={isRetrieving}
                            onChatHistorySessionClickHandler={onChatHistorySessionClickHandler}
                            onShowDialog={onShowDialog}
                        />
                        <div className={styles.newchat}>
                            <div role="button" aria-labelledby={newchatId} className={`${styles.button} ${newChatDisabled && "disabled"}`} onClick={clearChat}>
                                <ReactSVG className={styles.add} src={add} />
                                <label id={newchatId}>New Chat</label>
                            </div>
                        </div>
                    </>
                </SideBar>
                <Stack horizontal className={styles.chatRoot}>
                    <Stack.Item className={styles.chatContainer}>
                        {!lastQuestionRef.current ? (
                            <Stack className={styles.chatEmptyState}>
                                <div className={styles.chatIconContainer}>
                                    <ReactSVG src={BizData_short} className={styles.chatIcon} />
                                </div>
                                {welcomeText && (
                                    <>
                                        <h1 className={styles.chatEmptyStateTitle}>
                                            <ReactMarkdown components={{ p: 'span' }}>{welcomeText.title}</ReactMarkdown>
                                        </h1>
                                        {welcomeText.subtitle && (
                                            <h2 className={styles.chatEmptyStateSubtitle}>
                                                <ReactMarkdown components={{ p: 'span' }}>{welcomeText.subtitle}</ReactMarkdown>
                                            </h2>
                                        )}
                                    </>
                                )}

                            </Stack>
                        ) : (
                            <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                {answers.map((answer, index) => (
                                    <Fragment key={index}>
                                        {answer.role === "user" ? (
                                            <div className={styles.chatMessageUser} tabIndex={0} >
                                                <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                <UserIcon className={styles.chatUserIcon} />
                                            </div>
                                        ) : (
                                            answer.role === "assistant" ? (<div className={styles.chatMessageGpt} key={index}>
                                                <div className={styles.chatIconContainer} style={{ height: "60px", width: "60px" }}>
                                                    <ReactSVG
                                                        src={BizData_short}
                                                        className={styles.chatIcon}
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <Answer
                                                    answer={{
                                                        answer: answer.content,
                                                        citations: parseCitationFromMessage(answers[index - 1]),
                                                        answer_metadata: answer.answer_metadata
                                                    }}
                                                    isCurrent={index === answers.length - 1 && !showLoadingMessage}
                                                    onRegenerateClicked={regenerateAns}
                                                    onCitationClicked={c => onShowCitation(c)}
                                                />

                                            </div>)
                                                : answer.role === "error" ? (<div className={styles.chatMessageError} key={index}>
                                                    <Stack horizontal className={styles.chatMessageErrorHeader}>
                                                        <ErrorCircleRegular className={styles.errorIcon} />
                                                        <span>Error</span>
                                                    </Stack>
                                                    <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                                    {index === answers.length - 1 && !showLoadingMessage && (
                                                        <Stack horizontal className={styles.answerHeader}>
                                                            <div
                                                                className={styles.buttonContainer}
                                                                role="button"
                                                                tabIndex={0}
                                                                aria-label="Regenerate"
                                                                title="Regenerate response"
                                                                onClick={regenerateAns}
                                                                onKeyDown={e => e.key === "Enter" || e.key === " " ? regenerateAns() : null}
                                                            >
                                                                <ReactSVG className={styles.regenButton} src={regen} />
                                                            </div>
                                                        </Stack>
                                                    )}
                                                </div>) : null

                                        )}
                                    </Fragment>
                                ))}
                                {showLoadingMessage && (
                                    <>
                                        <div className={styles.chatMessageUser}>
                                            <div className={styles.chatMessageUserMessage}>{lastQuestionRef.current}</div>
                                            <UserIcon className={styles.chatUserIcon} />
                                        </div>
                                        <div className={styles.chatMessageGpt}>
                                            <div className={styles.chatIconContainer} style={{ height: "60px", width: "60px" }}>
                                                <ReactSVG
                                                    src={BizData_short}
                                                    className={styles.chatIcon}
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <Answer
                                                answer={{
                                                    answer: "Generating answer...",
                                                    citations: []
                                                }}
                                                isCurrent={false}
                                                onRegenerateClicked={async () => { ; }}
                                                onCitationClicked={() => null}
                                            />
                                        </div>
                                    </>
                                )}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <Stack className={styles.chatInput}>
                            {isLoading && (
                                <Stack
                                    horizontal
                                    className={styles.stopGeneratingContainer}
                                    role="button"
                                    aria-label="Stop generating"
                                    tabIndex={0}
                                    onClick={stopGenerating}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                >
                                    <span className={styles.stopGeneratingText} aria-hidden="true">Stop generating</span>
                                </Stack>
                            )}
                            <QuestionInput
                                clearOnSend
                                placeholder="Please type your question"
                                disabled={isLoading || isRetrieving}
                                onSend={question => makeApiRequest(question)}
                                toggleConfigPannel={() => {
                                    setConfigPivot("uploader");
                                    setIsConfigPanelOpen(!isConfigPanelOpen);
                                }}
                                tokensRemaining={tokensRemaining}
                                onTextChange={setQuestionText}
                            />
                        </Stack>
                    </Stack.Item>
                    {answers.length > 0 && isCitationPanelOpen && activeCitation && (
                        <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                            <Stack horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                <span className={styles.citationPanelHeader}>Citations</span>
                                <DismissRegular className={styles.citationPanelDismiss} onClick={() => setIsCitationPanelOpen(false)} />
                            </Stack>
                            <h5 className={styles.citationPanelTitle} tabIndex={0}>{activeCitation[2]}</h5>
                            {activeCitation[4] && (
                                <a href={activeCitation[4]} target="_blank">Link to data on website</a>
                            )}
                            <div tabIndex={0}>
                                <ReactMarkdown
                                    linkTarget="_blank"
                                    className={styles.citationPanelContent}
                                    children={activeCitation[0]}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>

                        </Stack.Item>
                    )}

                </Stack>
            </div>
            <ConfigurationPanel
                isConfigPanelOpen={isConfigPanelOpen}
                selectedPivot={configPivot}
                setSelectedPivot={setConfigPivot}
                setIsConfigPanelOpen={setIsConfigPanelOpen}
                onSelectedFilesChanged={setFileIds}
                onRemainingTokensChanged={setTokensRemaining}
                currentText={questionText}
                sessionId={activeSession?.chat_id}
                setSessionId={setSessionId}
                showSections={["uploader", "filters", "config"]}
                defaultSection="uploader"
            />

            <RecentActivityPanel
                isPanelOpen={isChatHistoryVisible}
                setPanelClose={closeChatHistory}
                isRetrieving={isRetrieving}
                historyState={historyStat}
                onChatHistorySessionClickHandler={onChatHistorySessionClickHandler}
                onShowDialog={onShowDialog}
            />

            <Dialog
                responsiveMode={0}
                hidden={hiddenDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                    className: styles.dialog
                }}
            >
                <DialogFooter>
                    <DefaultButton className={styles.cancelButton} theme={accentTheme} onClick={toggleHideDialog} text="Don't delete" />
                    <DefaultButton className={styles.confirmButton} theme={accentTheme} onClick={onConfirm} text="Delete" />
                </DialogFooter>
            </Dialog>
        </>
    )
};

export default Chat;
